import { AnyAction } from "@reduxjs/toolkit";
import { CURRENT_USER_ACTION_TYPES } from "../actions/currentUser";

const defaultPollworkerWebsiteState = {};

export function currentPollworkerUserReducer(state = defaultPollworkerWebsiteState, action: AnyAction) {
  switch (action.type) {
  case CURRENT_USER_ACTION_TYPES.LOGOUT_CURRENT_USER:
    return defaultPollworkerWebsiteState;
  case CURRENT_USER_ACTION_TYPES.SET_CURRENT_POLLWORKER_USER:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
}
