import * as React from "react"
import { format } from "date-fns"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./Popover";
import {Button} from "./Button";
import { Calendar } from "./Calendar";
import { cn } from "../../utils";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { inputVariants } from "./Input";
import { useEffect } from "react";
import { DayPickerProps } from "react-day-picker";

type DatePickerProps = { placeholder?: string, displayFormat?: string, sideOffset?: number, onSelect: (date: Date) => void } & DayPickerProps;

export function DatePicker({placeholder, displayFormat = 'PPP', sideOffset = -160, onSelect}: DatePickerProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>();

  useEffect(() => {
    if (!date) return;

    onSelect && onSelect(date);
  }, [date]);

  return (
    <Popover open={open} onOpenChange={(newOpen: boolean) => setOpen(newOpen)}>
      <PopoverTrigger asChild>
        <Button
          variant='secondary'
          className={cn(
            inputVariants({variant: 'new'}),
            "w-full justify-between",
            !date && "text-state-active"
          )}
          onClick={() => setOpen(true)}
        >
          {date ? format(date, displayFormat) : <span>{placeholder || 'Pick a date'}</span>}
          <CalendarIcon className="size-4 scale-150 h-full ml-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={sideOffset} className="w-auto p-0 shadow-xl">
        <Calendar
          selected={date}
          onSelect={(newDate: Date | undefined) => {
            setDate(newDate);
            setOpen(false);
          }}
          initialFocus
          variant='borderless'
          mode="single"
        />
      </PopoverContent>
    </Popover>
  );
}
