import tw from "tailwind-styled-components";

const ButtonGroup = tw.div`
  rounded-md
  overflow-hidden
  [&>button]:rounded-none
  flex
  divide-x
  divide-gray-400
`;

export default ButtonGroup;
