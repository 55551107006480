import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './store';

export interface FeatureFlagState {
  flags: {
    [key: string]: boolean
  }
}

const initialState: FeatureFlagState = {
  flags: {}
}

const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlagCheck: (state, action: PayloadAction<{ name: string, enabled: boolean }>) => {
      state.flags[action.payload.name] = action.payload.enabled;
    }
  }
});

export default slice.reducer;

export const { setFeatureFlagCheck } = slice.actions;

export const selectFeatureFlags = (state: AppState) => state.featureFlags.flags;
