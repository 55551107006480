import { combineReducers } from 'redux';

import deploymentsReducer, { DeploymentState } from './deployments';
import inventoryUiReducer, { InventoryUiState } from './ui';
import inventoryEquipmentReducer, { InventoryEquipmentState } from './inventoryEquipment';

export type InventoryState = {
  deployments: DeploymentState,
  ui: InventoryUiState,
  inventoryEquipment: InventoryEquipmentState
};

export default combineReducers({
  deployments: deploymentsReducer,
  ui: inventoryUiReducer,
  inventoryEquipment: inventoryEquipmentReducer
});
