import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils";
import { ScreenReaderHidden } from "../Accessibility";

const labelVariants = cva(
  "",
  {
    variants: {
      variant: {
        default: 'block text-sm font-medium leading-6 text-gray-900',
        new: 'text-[8.5px] md:text-[10px] lg:text-[11.5px] font-bold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants> &
  {required?: boolean}
>(({ className, variant, required, children, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({variant, className}), className)}
    {...props}
  >
    {children} {required ? <ScreenReaderHidden className='text-error'>*</ScreenReaderHidden> : null}
  </LabelPrimitive.Root>
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
