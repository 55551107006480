import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Precinct, VotingLocation, WorkSchedule } from '../../types';

export interface PollworkerTrackerState {
  locationTypeDisplay: 'votingLocation' | 'precinct';
  precincts: Precinct[];
  loadingPrecincts: boolean,
  votingLocations: VotingLocation[];
  loadingVotingLocations: boolean,
  selectedPrecinct: Precinct | null;
  selectedVotingLocation: VotingLocation | null;
  workSchedules: WorkSchedule[],
}

const initialState: PollworkerTrackerState = {
  locationTypeDisplay: 'votingLocation',
  loadingPrecincts: false,
  loadingVotingLocations: false,
  precincts: [],
  votingLocations: [],
  selectedPrecinct: null,
  selectedVotingLocation: null,
  workSchedules: [],
};

const slice = createSlice({
  name: 'pollworkerTracker',
  initialState,
  reducers: {
    setDisplayedLocationType: (state, action: PayloadAction<'votingLocation' | 'precinct'>) => {
      state.locationTypeDisplay = action.payload;
    },
    setPrecinctsForElectionAndDate: (state, action: PayloadAction<Precinct[]>) => {
      state.precincts = action.payload;
    },
    setVotingLocationsForElectionAndDate: (state, action: PayloadAction<VotingLocation[]>) => {
      state.votingLocations = action.payload;
    },
    setSelectedPrecinct: (state, action: PayloadAction<Precinct>) => {
      state.selectedPrecinct = action.payload;
    },
    setSelectedVotingLocation: (state, action: PayloadAction<VotingLocation>) => {
      state.selectedVotingLocation = action.payload;
    },
    setTrackerWorkSchedules: (state, action: PayloadAction<WorkSchedule[]>) => {
      state.workSchedules = action.payload;
    },
    updateTrackerWorkSchedule: (state, action: PayloadAction<WorkSchedule>) => {
      const index = state.workSchedules.findIndex(schedule => schedule.id === action.payload.id);

      if (index !== -1) {
        state.workSchedules[index] = action.payload;
      }
    }
  }
});

export default slice.reducer;

export const {
  setDisplayedLocationType,
  setPrecinctsForElectionAndDate,
  setVotingLocationsForElectionAndDate,
  setSelectedVotingLocation,
  setSelectedPrecinct,
  setTrackerWorkSchedules,
  updateTrackerWorkSchedule,
} = slice.actions;
