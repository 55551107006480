import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { moduleInfoSelector } from "../../../selectors/pollworker/grid";
import { AnySlot, NodeType, PartiesByCode, PartiesById } from "./pollworkerVotingLocationDetailsUtils";
import { getPollworkerSelector } from "../../../selectors/pollworker";
import { useIntersection } from "react-use";
import { deletePollworkerSchedule, getPartiesForCustomer } from "../../../fetchers";
import { setParties, setShowPollworkerDetailsId } from "../../../reducers/pollworker/grid";
import { Party, PollworkerWorkHistory } from 'admin/src/types';
import dayjs from "dayjs";
import { classNames } from "shared/src/utils";
import { ArrowPathIcon, CakeIcon, ChevronDownIcon, IdentificationIcon, InformationCircleIcon, UsersIcon } from "@heroicons/react/24/outline";
import { Button, Popover, PopoverContent, PopoverTrigger } from "shared/src/components/ui";
import { Flexor, Spinner, TippyContent } from "shared/src/components";
import Tippy from "@tippyjs/react";
import PollworkerVotingLocationPicker from "./PollworkerVotingLocationPicker";
import { PollworkerRequirements, VotingLocationDisplayProps } from "./types";
import ScheduleDateAdorners from "./SchedulerAdorners";
import { addToSchedule } from "./utils/pollworkerScheduler";
import PollworkerVotingLocationNoSchedules from "./PollworkerVotingLocationNoSchedules";
import useVotingLocationData from "../../../hooks/useVotingLocationData";
import VotingLocationAdorners from "./PollworkerVotingLocationAdorners";

const PARTY_CODE_TO_COLOR: { [code: string]: string } = {
  R: 'bg-red-500 border-red-700',
  D: 'bg-blue-500 border-blue-700',
  I: 'bg-yellow-500 border-yellow-700',
};

export default function PollworkerVLSwimlane({ parties, votingLocation, loading: loadingPollworkerData }: VotingLocationDisplayProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const moduleInfo = useSelector(moduleInfoSelector);
  const [pickerRequirements, setPickerRequirements] = useState<PollworkerRequirements>({});
  const [showPollworkerPicker, setShowPollworkerPicker] = useState<string>();
  const pollworkers = useSelector(getPollworkerSelector);
  const [partiesByCode, setPartiesByCode] = useState<PartiesByCode>({});
  const [loading, hasLoaded, scheduleDates, getVotingLocationData] = useVotingLocationData(parties, votingLocation?.id, false);
  const dispatch = useDispatch();
  const intersection = useIntersection(containerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  });

  const customerId = window.sessionStorage.getItem('customerId');

  useEffect(() => {
    if (!customerId) return;

    getPartiesForCustomer(customerId).then((resp) => {
      setParties(resp.reduce((acc: PartiesById, party: Party) => {
        acc[party.id] = party;
        return acc;
      }, {}));

      setPartiesByCode(resp.reduce((acc: PartiesById, party: Party) => {
        acc[party.partyCode] = party;
        return acc;
      }, {}));
    });
  }, [customerId]);

  useEffect(() => {
    if (!intersection?.isIntersecting || !moduleInfo?.Election || hasLoaded) return;

    getVotingLocationData();
  }, [intersection, hasLoaded, moduleInfo]);

  function handleShowPollworkerPicker(slot: AnySlot, date: string, slideoutKey: string) {
    dispatch(setShowPollworkerDetailsId(undefined));
    setPickerRequirements({
      scheduleDate: new Date(date).toISOString(),
      roleInfoId: slot.role.id,
      party: partiesByCode[slot.party],
    });
    setShowPollworkerPicker(slideoutKey);
  }

  async function handleAddPollworkerSchedule(isSwap: boolean, slot: AnySlot, pollworker: PollworkerWorkHistory, startTime: string, endTime: string, notes: string = '') {
    if (!moduleInfo?.ElectionId) return;

    if (isSwap) await deletePollworkerSchedule(slot.schedule);

    await addToSchedule(votingLocation.id, moduleInfo.ElectionId, pickerRequirements.roleInfoId, pickerRequirements.scheduleDate, pollworker, startTime, endTime, notes);

    getVotingLocationData();
    setShowPollworkerPicker(undefined);
  }

  return (
    <div ref={containerRef} className={classNames('relative h-full overflow-y-auto min-w-96 bg-white border-2 px-2 pb-5 shadow ring-1 ring-gray-900/5 sm:max-w-lg sm:rounded-lg')}>
      <div className="sticky flex justify-between items-center top-0 z-40 py-2.5 bg-white mb-0 font-semibold">
        <Flexor className='space-x-2'>
          <div>{votingLocation?.locationName}</div>
          <VotingLocationAdorners votingLocation={votingLocation}/>
          <div onClick={getVotingLocationData}>
            <ArrowPathIcon className={classNames(hasLoaded && loading ? 'animate-spin' : '', 'size-4')}/>
          </div>
        </Flexor>
        <div>{votingLocation?.locationCode}</div>
      </div>
      <PollworkerVotingLocationNoSchedules show={!loading && !!intersection?.isIntersecting && !scheduleDates.length} refresh={getVotingLocationData}/>
      <div className="space-y-5">
        {
          loading && !hasLoaded ? (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
              <Spinner show large/>
            </div>
          ) : (
            <>
              {
                scheduleDates.length ? (
                  <>
                    {
                      scheduleDates.map(([date, schedulesByRole], dateIdx) => {
                        const {
                          isUnassignedDate,
                          overProvisionedIndex,
                          hasUnrequestedParty,
                        } = schedulesByRole.meta;

                        const containsError = isUnassignedDate || hasUnrequestedParty || (overProvisionedIndex && overProvisionedIndex >= 0);
                        const formattedDate = dayjs(date).format('ddd, MMM D, YYYY');

                        return (
                          <div
                            key={`${votingLocation.id}_${date}`}
                            className={classNames(
                              containsError ? 'border-red-500' : 'border-gray-300',
                              (hasLoaded && loading) || loadingPollworkerData ? 'opacity-50' : '',
                              'space-y-2 rounded border bg-gray-50 p-2 transition-opacity'
                            )}
                          >
                            <div className="sticky top-11 z-20 bg-gray-50 flex justify-between">
                              <div className={classNames(containsError ? 'text-red-500' : '', 'flex items-center space-x-1')}>
                                <div>{formattedDate}</div>
                                <ScheduleDateAdorners schedulesByRole={schedulesByRole}/>
                              </div>
                              <ChevronDownIcon className="size-5"/>
                            </div>
                            {
                              Object.entries(schedulesByRole.roles).map(([roleName, schedules], roleIdx) => {
                                const data = schedules;
                                const allowedPartyCodes = Object.keys(data.workerCountByDateByRoleByParty);

                                return (
                                  <div key={`${votingLocation.id}_${date}_${roleName}`} className='space-y-2'>
                                    {
                                      <div className="flex items-center justify-between px-0.5">
                                        <div className="flex items-center space-x-2">
                                          <div>{roleName}</div>
                                          {
                                            data.meta?.isGlobalRole ? (
                                              <Tippy content={<TippyContent>This role is added when a date isn't specified in "Voting Location Positions".</TippyContent>}>
                                                <InformationCircleIcon className="size-4"/>
                                              </Tippy>
                                            ) : null
                                          }
                                        </div>
                                        <div className="flex items-center space-x-1">
                                          <div className="flex items-center rounded overflow-hidden text-xs">
                                            {
                                              allowedPartyCodes.map((code) => {
                                                if (!code || code === 'undefined') return null;

                                                const count = data.workerCountByDateByRoleByParty?.[code];
                                                const partyName = partiesByCode[code]?.partyName;
                                                const bgColor = PARTY_CODE_TO_COLOR[code];

                                                return (
                                                  <Tippy key={`${date}_${roleName}_party_${code}`} content={<TippyContent>{count} {partyName}</TippyContent>}>
                                                    <div key={`${date}_party_${roleName}_${code}`} className={classNames(bgColor ? `${bgColor} text-white` : 'text-gray-800 bg-gray-300', 'py-0.5 px-1 border text-xs')}>
                                                      {count} {code}
                                                    </div>
                                                  </Tippy>
                                                );
                                              })
                                            }
                                          </div>
                                          <div className="flex items-center space-x-1 text-xs">
                                            {
                                              data.workerCountByRole[roleName] ? (
                                                <Tippy content={<TippyContent>Total number of positions allowed</TippyContent>}>
                                                  <div className="rounded border border-gray-400 py-0.5 px-1 flex items-center space-x-1">
                                                    <UsersIcon className="size-3"/>
                                                    <span>{data.workerCountByRole[roleName]}</span>
                                                  </div>
                                                </Tippy>
                                              ) : null
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    {
                                      data.slots.map((slot: AnySlot, slotIdx: number) => {
                                        const key = `${date}_${slot.nodeType}_${dateIdx}_${roleIdx}_${slotIdx}`;
                                        const slideoutKey = [votingLocation.id, date, roleName, slotIdx].join('-');
                                        const slideoutBeingShown = !!showPollworkerPicker;
                                        const showSlideout = slideoutKey === showPollworkerPicker;
                                        const isEmpty = slot.nodeType !== NodeType.scheduled;
                                        const isOverProvisioned = slot.nodeType === NodeType.overprovisioned;
                                        const allowedParties = allowedPartyCodes.map((partyCode) => partiesByCode[partyCode]?.partyName)
                                        .filter(p => !!p)
                                        .join(', ');

                                        if (isOverProvisioned) {
                                          return (
                                            <div key={key} className="w-full mx-auto flex justify-center relative">
                                              <span className="text-xs bg-gray-50 px-2 -mt-0.5 z-10">Over-provisioned</span>
                                              <div className="h-0.5 w-full absolute top-1 bg-ev-red"/>
                                            </div>
                                          );
                                        }

                                        return (
                                          <div key={key} className={classNames(slideoutBeingShown && !showSlideout ? 'opacity-50' : '', "space-y-2 text-sm")}>
                                            <div className={classNames(showSlideout ? 'ring-4 ring-ev-red' : 'border border-gray-300', 'rounded bg-white p-2')}>
                                              <div className="flex justify-between items-center" data-testid={`${votingLocation.locationCode}-${date}-${slot.pollworkerInfo?.id}`}>
                                                <div>
                                                  {
                                                    isEmpty ? (
                                                      <span className="italic text-gray-600">{`Unassigned [${slot.party ? partiesByCode[slot.party[0]]?.partyName || 'Unknown' : 'OPEN'}]`}</span>
                                                    ) : (
                                                      <>
                                                        <Flexor className="space-x-1" justify='start'>
                                                          <span>
                                                            {`${slot.pollworkerInfo?.firstName} ${slot.pollworkerInfo?.lastName}`}
                                                          </span>
                                                          <IdentificationIcon onClick={(event) => {
                                                            event.stopPropagation();

                                                            if (isEmpty) return;

                                                            const pollworker = pollworkers.find((pw: PollworkerWorkHistory) => pw.keyEVUserId === slot?.keyEVUserId);

                                                            dispatch(setShowPollworkerDetailsId(pollworker?.id));
                                                          }} className='size-4'/>
                                                        </Flexor>
                                                        <div className="mt-2 text-xs">
                                                          <div className='flex items-center space-x-2'>
                                                            {slot.assigneeParty ? partiesByCode[slot.assigneeParty]?.partyName : 'No party'}
                                                            {
                                                              slot.meta.isUnrequestedParty ? (
                                                                <Tippy content={<TippyContent>This worker's party does not meet the requirement of {allowedParties}.</TippyContent>}>
                                                                  <CakeIcon className='size-3 ml-1'/>
                                                                </Tippy>
                                                              ) : null
                                                            }
                                                          </div>
                                                          <div>{slot.schedule?.startTime} - {slot.schedule?.endTime}</div>
                                                        </div>
                                                      </>
                                                    )
                                                  }
                                                </div>
                                                <div className="flex items-center">
                                                  <Popover open={showSlideout} onOpenChange={(open) => {
                                                    if (!open) {
                                                      setShowPollworkerPicker(undefined);
                                                      return;
                                                    }

                                                    handleShowPollworkerPicker(slot, date, slideoutKey);
                                                  }}>
                                                    <PopoverTrigger asChild>
                                                      <Button variant="quaternary" className="p-0">{isEmpty ? 'Add' : 'Swap'}</Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className='relative max-h-[400px] min-h-[400px] overflow-y-auto overflow-x-hidden p-0 bg-white'>
                                                      <PollworkerVotingLocationPicker
                                                        partiesByCode={partiesByCode}
                                                        locationAssignments={data}
                                                        onAdd={(pollworker: PollworkerWorkHistory, startTime: string, endTime: string, notes: string) => {
                                                          handleAddPollworkerSchedule(!isEmpty, slot, pollworker, startTime, endTime, notes);
                                                        }}
                                                        requirements={pickerRequirements}
                                                        votingLocation={votingLocation}
                                                      />
                                                    </PopoverContent>
                                                  </Popover>
                                                  {!isEmpty ? (
                                                    <Button onClick={async () => {
                                                      if (!window.confirm(`Are you sure you want to remove ${slot.pollworkerInfo?.firstName} ${slot.pollworkerInfo?.lastName} as a ${slot.role.roleName} on ${formattedDate}?`)) return;
                                                      await deletePollworkerSchedule(slot.schedule);
                                                      getVotingLocationData();
                                                      setShowPollworkerPicker(undefined);
                                                    }} variant="quaternary" className="p-0">
                                                      Remove
                                                    </Button>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    }
                                  </div>
                                );
                              })
                            }
                          </div>
                        );
                      })
                    }
                  </>
                ) : null
              }
            </>
          )
        }
      </div>
    </div>
  );
}
