import React from "react";
import {
  ColDef, ColGroupDef,
  IRichCellEditorParams,
  ValueGetterParams
} from "ag-grid-community";
import {AppState} from "../../reducers";
import {createSelector} from "@reduxjs/toolkit";
import {ModuleInfoDisplayState} from "../../types/Pollworker/PollworkerModuleInfo";
import {
  getCustomFieldsByPromptTextKeysSelector,
  getCustomFieldsByPromptTextSelector,
  getReportsTo, getReportsToByIdSelector,
  getRoleTypes
} from "./index";
import {WorkerLevelById} from "../../components/enums/WorkerLevelEnum";
import CellIconWrapper from "../../components/Pollworker/utils/CellIconWrapper";
import StatusCellRenderer, {workerStatusContent} from "../../components/Pollworker/utils/CellStatusRenderer";
import { Flexor } from "shared/src/components"
import {SimpleTippyContent} from "shared/src/components/TippyContent";
import {dateFormatter, phoneNumberFormatter} from "shared/src/utils/formatters";
import { Party, PollworkerWorkHistory, Precinct } from 'admin/src/types';
import dayjs from "dayjs";
import {STATES_BY_ABBR} from "shared/src/utils/data/usStates";
import validator from 'validator';
import {PollworkerColUpdate} from "../../components/Pollworker/PollworkerGrid";

interface PollworkerColumnDef {
  _simpleRenderer?: (value: any) => void
  _showMenu?: boolean,
  _update?: PollworkerColUpdate,
}

type PollworkerColDef = ColDef & PollworkerColumnDef;

type PollworkerColGroupDef = ColGroupDef & {
  children: PollworkerColDef[]
}

export const moduleInfoSelector = (state: AppState) => state.pollworker.moduleInfo;
const precinctsSelector = (state: AppState) => state.pollworker.grid.precincts;
const partiesSelector = (state: AppState) => state.pollworker.grid.parties;

export const displayStatesByIdSelector = createSelector([moduleInfoSelector], (moduleInfo) => {
  if (!moduleInfo) return;

  return moduleInfo.DisplayStates.reduce((acc: { [key: string]: ModuleInfoDisplayState }, state) => {
    acc[state.id] = state;
    return acc;
  }, {});
});

export const displayStatesByNameSelector = createSelector([moduleInfoSelector], (moduleInfo) => {
  if (!moduleInfo) return;

  return moduleInfo.DisplayStates.reduce((acc: { [key: string]: ModuleInfoDisplayState }, state) => {
    acc[state.StateName] = state;
    return acc;
  }, {});
});

const selectedRowsPollworkerIds = (state: AppState) => state.pollworker.grid.selectedRowsPollworkerIds;
const pollworkers = (state: AppState) => state.pollworker.grid.pollworkers;
export const getSelectedPollworkers = createSelector([selectedRowsPollworkerIds, pollworkers], (selectedIds, pollworkers) => {
  const pollworkersById = pollworkers.workerList.reduce((acc, pw: PollworkerWorkHistory): any => {
    return {...acc, [pw.id]: pw};
  }, {});

  return selectedIds.map((id: string) => {
    // @ts-ignore
    return pollworkersById[id];
  });
});

function getRowDisplayState(params: any, displayStatesById?: {[p: string]: ModuleInfoDisplayState}) {
  if (!displayStatesById) return;

  const row = params.node;
  if (!displayStatesById || !row || !row.data || !row.data.pollworkerInfo.displayStateId) return;

  return displayStatesById[row.data.pollworkerInfo.displayStateId];
}

export const agGridColDefsSelector = createSelector([
  partiesSelector,
  precinctsSelector,
  getReportsTo,
  moduleInfoSelector,
  getCustomFieldsByPromptTextKeysSelector,
  getRoleTypes,
  getCustomFieldsByPromptTextSelector,
  getReportsToByIdSelector,
  (state: AppState) => state.user,
  displayStatesByIdSelector,
  displayStatesByNameSelector,
], (parties, precincts, reportsTo, moduleInfo, customFieldsByName, roleTypes, customFieldsByPromptText, reportsToById, user, displayStatesById, displayStatesByName)
  : (PollworkerColDef[] | PollworkerColGroupDef[]) => {
  return [
    {
      field: 'pollworkerInfo.userInfo.fullName',
      pinned: 'left',
      valueGetter: (params: ValueGetterParams) => params.node?.group ? null : `${params.data.pollworkerInfo.userInfo.firstName} ${params.data.pollworkerInfo.userInfo.lastName}`,
      filter: true,
      enableCellChangeFlash: true,
      headerName: 'Full Name',
    },
    {
      headerName: 'Flag',
      width: 40,
      pinned: 'left',
      field: 'static',
      filter: true,
      filterParams: {
        cellRenderer: (params: any) => {
          if (!displayStatesByName) return;

          const displayState = displayStatesByName[params.value];
          if (!displayState) return params.value || '(Blanks)';

          return (
            <Flexor className='space-x-1'>
              <div className="h-5 w-5" style={{backgroundColor: `#${displayState.BackColor}`}} />
              <div>{displayState.StateName}</div>
            </Flexor>
          );
        }
      },
      filterValueGetter: (params: any) => {
        const displayState = getRowDisplayState(params, displayStatesById);
        if (!displayState) return params.value;

        return displayState.StateName;
      },
      tooltipValueGetter: (params: any) => {
        const displayState = getRowDisplayState(params, displayStatesById);
        if (!displayState) return;

        return displayState.StateName;
      },
      cellStyle: (params: any) => {
        const displayState = getRowDisplayState(params, displayStatesById);
        if (!displayState) return;

        return {
          background: `#${displayState.BackColor}`,
        }
      },
    },
    {
      field: 'pollworkerInfo.status',
      pinned: 'left',
      lockPosition: true,
      headerName: 'Status',
      width: 70,
      suppressColumnsToolPanel: true,
      enableRowGroup: true,
      filter: 'agSetColumnFilter',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      getQuickFilterText: (params: any) => {
        return workerStatusContent(Number(params.value))?.content;
      },
      valueFormatter: (params: any) => {
        const {content} = workerStatusContent(Number(params.value));
        return content;
      },
      filterParams: {
        cellRenderer: (params: any) => {
          if (typeof params.value === 'string') return params.value;

          return (
            <CellIconWrapper>
              <StatusCellRenderer params={params} filterDisplay/>
            </CellIconWrapper>
          );
        }
      },
      cellRenderer: (params: any) => <CellIconWrapper><StatusCellRenderer params={params}/></CellIconWrapper>,
      _simpleRenderer: (value: any) => {
        const {content, icon} = workerStatusContent(value);

        return (
          <Flexor justify="end">
            <SimpleTippyContent content={content}>
              {icon}
            </SimpleTippyContent>
          </Flexor>
        )
      }
    },
    {
      colId: 'workerLevelKey',
      field: 'workerLevel',
      headerName: 'Level',
      filter: true,
      width: 100,
      filterValueGetter: (params: any) => {
        return WorkerLevelById[params.data.workerLevel]?.displayName;
      },
      valueFormatter: (params: any): any => {
        if (params.node.group) return null;

        return WorkerLevelById[params.value].displayName;
      },
    },
    {
      field: 'pollworkerInfo.userInfo.userId',
      filter: true,
      headerName: 'User ID',
      editable: false,
      width: 100,
    },
    {
      field: 'pollworkerInfo.userInfo.okToSendSMS',
      filter: true,
      headerName: 'SMS?',
      editable: true,
      enableRowGroup: true,
      valueFormatter: (params: any) => {
        return params.value ? 'Yes' : 'No';
      },
      width: 80,
      _update: {updateType: 'userInfo'},
    },
    {
      field: 'pollworkerInfo.electionDayWorker',
      editable: true,
      enableRowGroup: true,
      width: 156,
      _update: {updateType: 'pollworkerInfo'},
      filter: true,
      headerName: 'Election Day Worker'
    },
    {
      field: 'pollworkerInfo.earlyVotingWorker',
      _update: {updateType: 'pollworkerInfo'},
      editable: true,
      width: 150,
      filter: true,
      enableRowGroup: true,
      headerName: 'Early Voting Worker'
    },
    {
      field: '_workAssignmentConfirmation.value',
      filter: true,
      editable: true,
      headerName: 'Work Confirmation',
      _update: {
        updateType: 'workConfirmation',
      },
      valueFormatter: (params: any) => {
        if (params.value === 1) return 'Accepted';
        if (params.value === 2) return 'Declined';
        return 'No Response';
      },
      cellEditorSelector: () => ({
        component: 'agRichSelectCellEditor',
        params: {
          cellRenderer: (params: any) => {
            return (
              <div className="">
                {params.value === 0 ? 'Reset' : null}
                {params.value === 1 ? 'Accept' : null}
                {params.value === 2 ? 'Decline' : null}
              </div>
            );
          },
          values: [0, 1, 2],
        },
        popup: true,
      })
    },
    {
      colId: 'electionDay',
      headerName: 'Election Day',
      children: [
        {
          field: 'electionDayRole.roleName',
          filter: true,
          headerName: 'Role',
        },
        {
          filter: true,
          field: 'electionDayLocation.locationName',
          headerName: 'Location',
        },
        {
          filter: true,
          headerName: 'Location Address',
          columnGroupShow: 'open',
          valueGetter: (params) => {
            return [
              params.data.electionDayLocation?.address1,
              params.data.electionDayLocation?.address2,
              params.data.electionDayLocation?.state,
              params.data.electionDayLocation?.city,
              params.data.electionDayLocation?.zip,
            ].filter(p => !!p).join(', ');
          }
        },
        {
          columnGroupShow: 'closed',
          filter: true,
          headerName: 'Precinct',
          valueGetter: (params: any) => {
            const precinctInfo = params.data.electionDayPrecinct;
            return [precinctInfo?.precinctCode, precinctInfo?.precinctName].filter(p => !!p).join(' - ');
          },
        },
        {
          columnGroupShow: 'open',
          field: 'electionDayPrecinct.precinctCode',
          filter: true,
          headerName: 'Precinct Code',
        },
        {
          columnGroupShow: 'open',
          field: 'electionDayPrecinct.precinctName',
          filter: true,
          headerName: 'Precinct Name',
        }
      ]
    },
    {
      field: 'assignedToClass',
      width: 130,
      filter: true,
      enableRowGroup: true,
      headerName: 'Training',
      cellDataType: 'boolean'
    },
    {
      field: 'pollworkerInfo.party',
      filter: true,
      enableRowGroup: true,
      width: 100,
      headerName: 'Party',
      filterValueGetter: (params: any) => {
        const value = params.data.pollworkerInfo.party;
        const party = parties.find(p => value === p.partyCode);
        return party ? party.partyName : (value || 'Unspecified');
      },
      getQuickFilterText: (params) => {
        const value = params.value ? params.value : '';
        const party = parties.find(p => params.value === p.partyCode);
        return party ? party.partyName : (value || 'Unspecified');
      },
      valueFormatter: (params: any) => {
        const value = params.value ? params.value : '';
        const party = parties.find(p => params.value === p.partyCode);
        return party ? party.partyName : value;
      },
      editable: true,
      _update: {updateType: 'pollworkerInfo'},
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: async () => {
              if (!moduleInfo?.Election) return;

              return parties.map((p: Party) => p.partyCode);
            },
            cellRenderer: (params: any) => {
              const party = parties.find(p => p.partyCode === params.value);

              if (!party) return null;

              return (
                <div className="w-full space-x-2">
                  <span className="inline-block w-[2em]">{party.partyCode}</span>
                  <span className="inline-block capitalize">{party.partyName}</span>
                </div>
              );
            },
          },
          allowTyping: true,
          popup: true,
          cellHeight: 50,
        }
      }
    },
    {
      field: 'pollworkerInfo.registeredPrecinctInfo.precinctName',
      filter: true,
      headerName: 'Registered Precinct',
      enableRowGroup: true,
      editable: true,
      _update: {
        updateType: 'pollworkerInfo',
        isKeyUpdate: true,
        keyPathToUpdate: 'keyRegisteredPrecinctId',
        getValue: (pollworker: PollworkerWorkHistory, newValue: string) => {
          const newPrecinct = precincts.find(p => p.precinctName === newValue);

          if (!newPrecinct) return pollworker.pollworkerInfo.registeredPrecinctInfo.keyPrecinctId;

          return {keyRegisteredPrecinctId: newPrecinct.id};
        }
      },
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: () => {
              return precincts.map((p: Precinct) => p.precinctName);
            },
          },
          allowTyping: true,
          popup: true,
          cellHeight: 50,
        }
      }
    },
    {
      field: '_reportsTo.id',
      filter: true,
      headerName: 'Reports To',
      enableRowGroup: true,
      editable: reportsTo.length > 0,
      _update: {
        updateType: 'roleInfo2'
      },
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: any): any => {
        return reportsToById[params.value]?._fullName;
      },
      cellEditorParams: {
        values: reportsTo.map(rt => rt.id),
        cellRenderer: (params: any) => {
          const reportTo = reportsTo.find(rt => rt.id === params.value);
          return reportTo ? (<div className="inline-block capitalize">{reportTo.firstName} {reportTo.lastName}</div>) : null;
        },
        filterList: true,
        searchType: 'match',
        allowTyping: true,
      } as IRichCellEditorParams,
    },
    {
      field: 'pollworkerInfo.notes',
      editable: true,
      filter: true,
      headerName: 'Notes',
      _update: {updateType: 'pollworkerInfo'}
    },
    {
      field: 'pollworkerInfo.userInfo.employeeNumber',
      filter: true,
      headerName: user?.account.pollworkerEmployeeIdDisplayName
    },
    {
      field: 'pollworkerInfo.userInfo.voterRegistrationNumber',
      filter: true,
      headerName: 'VR #',
      editable: true,
      _update: {updateType: 'userInfo'},
    },
    {
      field: 'pollworkerInfo.onlineLearningId',
      filter: true,
      headerName: 'Online Learning ID',
      editable: true,
      _update: {updateType: 'pollworkerInfo'}
    },
    {
      headerName: 'Personal Info',
      children: [
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.prefix',
          filter: true,
          editable: true,
          headerName: 'Prefix',
          // @ts-ignore
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.firstName',
          editable: true,
          filter: true,
          headerName: 'First Name',
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.middleName',
          editable: true,
          filter: true,
          headerName: 'Middle Name',
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.lastName',
          editable: true,
          filter: true,
          headerName: 'Last Name',
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.suffix',
          filter: true,
          editable: true,
          headerName: 'Suffix',
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'closed',
          headerName: 'Full Name',
          filter: true,
          colId: 'fullName',
          enableRowGroup: true,
          editable: false,
          enableCellChangeFlash: true,
          valueGetter: (params: any) => {
            const {pollworkerInfo} = params.data;

            return [
              pollworkerInfo.userInfo.prefix,
              pollworkerInfo.userInfo.firstName,
              pollworkerInfo.userInfo.middleName,
              pollworkerInfo.userInfo.lastName,
              pollworkerInfo.userInfo.suffix,
            ].filter(n => !!n).join(' ')
          }
        },
        {
          field: 'pollworkerInfo.userInfo.homePhone',
          headerName: 'Home Phone',
          editable: true,
          valueFormatter: phoneNumberFormatter,
          _update: {updateType: 'userInfo'}
        },
        {
          field: 'pollworkerInfo.userInfo.cellPhone',
          headerName: 'Cell Phone',
          editable: true,
          valueFormatter: phoneNumberFormatter,
          _update: {updateType: 'userInfo'}
        },
        {
          field: 'pollworkerInfo.userInfo.emailAddress',
          filter: true,
          headerName: 'Email Address',
          editable: true,
          _update: {updateType: 'userInfo'},
          valueGetter: (params: any) => {
            if (params.node.group) return null;

            return params.data.pollworkerInfo.userInfo.emailAddress;
          },
          valueSetter: (params: any) => {
            params.data.pollworkerInfo.userInfo.emailAddress = params.newValue;
            return validator.isEmail(params.newValue);
          }
        },
        {
          field: 'pollworkerInfo.dateOfBirth',
          editable: true,
          filter: true,
          headerName: 'Date Of Birth',
          cellDataType: 'date',
          valueFormatter: dateFormatter,
          _update: {
            updateType: 'pollworkerInfo',
            isKeyUpdate: true,
            getValue: (pollworker: PollworkerWorkHistory, newValue: any) => {
              return {
                dateOfBirth: dayjs(newValue).toISOString(),
              };
            }
          }
        },
        {
          field: 'pollworkerInfo.gender',
          filter: true,
          enableRowGroup: true,
          headerName: 'Gender',
          editable: true,
          _update: {updateType: 'pollworkerInfo'},
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: ['U', 'M', 'F', ''],
            cellRenderer: (params: any) => {
              return (
                <div>
                  {params.value === 'U' ? 'Unspecified' : null}
                  {params.value === 'M' ? 'Male' : null}
                  {params.value === 'F' ? 'Female' : null}
                  {params.value === '' || params.value === null ? 'Other' : null}
                </div>
              );
            }
          }
        },
      ]
    },
    {
      headerName: 'Mailing Address',
      children: [
        {
          headerName: 'Full Address',
          enableCellChangeFlash: true,
          valueGetter: (params: any) => {
            return [
              params.data.pollworkerInfo.userInfo.address1,
              params.data.pollworkerInfo.userInfo.address2,
              params.data.pollworkerInfo.userInfo.state,
              params.data.pollworkerInfo.userInfo.city,
              params.data.pollworkerInfo.userInfo.zip,
            ].filter(p => !!p).join(', ');
          },
          width: 300,
          columnGroupShow: 'closed',
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.address1',
          headerName: 'Address 1',
          editable: true,
          // @ts-ignore
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.address2',
          headerName: 'Address 2',
          editable: true,
          _update: {updateType: 'userInfo'}
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.city',
          headerName: 'City',
          enableRowGroup: true,
          editable: true,
          _update: {updateType: 'userInfo'},
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.state',
          headerName: 'State',
          enableRowGroup: true,
          editable: true,
          _update: {updateType: 'userInfo'},
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: Object.keys(STATES_BY_ABBR),
            cellRenderer: (params: any) => (
              <div className="w-full space-x-2">
                <span className="inline-block w-[2em]">{STATES_BY_ABBR[params.value].abbr}</span>
                <span className="inline-block capitalize">{STATES_BY_ABBR[params.value].state.toLowerCase()}</span>
              </div>
            ),
            filterList: true,
            searchType: 'match',
            allowTyping: true,
          } as IRichCellEditorParams,
        },
        {
          columnGroupShow: 'open',
          field: 'pollworkerInfo.userInfo.zip',
          headerName: 'Zip',
          enableRowGroup: true,
          editable: true,
          _update: {updateType: 'userInfo'}
        },
      ]
    },
    {
      headerName: 'Custom Fields',
      children: customFieldsByName.map((key: string) => {
        let dataType;
        const props: Partial<ColDef> = {};

        // TODO: Optimize this
        switch (customFieldsByPromptText[key].dataTypeId) {
          case 4:
            dataType = 'date';
            props.valueFormatter = dateFormatter;
            break;
          case 3:
            dataType = 'boolean';
            props.valueFormatter = (params: any) => {
              return params.value === 'true' ? 'Yes' : 'No';
            };
            break;
          case 2:
            dataType = 'number';
            break;
          case 0:
          case 1:
          default:
            dataType = 'text';
        }

        return {
          field: `_customFields.${key}`,
          headerName: key,
          cellDataType: dataType,
          enableRowGroup: true,
          ...props,
        };
      }),
    },
    {
      field: 'dateAdded',
      filter: true,
      headerName: 'Date Added',
      cellDataType: 'date',
      valueFormatter: dateFormatter
    },
  ];
});
