import React from "react";
import {
  ComponentCollection,
  ElementFactory,
  FunctionFactory,
  Serializer,
  settings
} from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import {
  MARKDOWN_TEXT_QUESTION_TYPE,
  POLITICAL_PARTY_QUESTION_TYPE,
  MarkdownTextModel,
  SurveyQuestionMarkdownText
} from "shared/src/components/QuestionMarkdownText";

// INITIALIZE custom SurveyJS configuration

// Create a custom question type with hard coded properties for Political Party
ComponentCollection.Instance.add({
  name: POLITICAL_PARTY_QUESTION_TYPE,
  title: 'Political Party',
  defaultQuestionTitle: 'Political Party',
  iconName: 'icon-dropdown',
  questionJSON: {
    'type': 'dropdown',
    'name': POLITICAL_PARTY_QUESTION_TYPE,
    'title': 'Political Party',
    'choicesByUrl': {
      'url': '{pollworkerWebsiteApiHostname}/customer/politicalparties/{customerId}',
      'valueName': 'PartyCode',
      'titleName': 'PartyName'
    }
  },
  inheritBaseProps: true
});

ElementFactory.Instance.registerElement(MARKDOWN_TEXT_QUESTION_TYPE, (name) => {
  return new MarkdownTextModel(name);
});

// Create "General" settings for entering markdown text and setting the "question" to be rendered via <Alert />
Serializer.addClass(
  MARKDOWN_TEXT_QUESTION_TYPE,
  [
    {
      name: 'markdownText:text',
      category: 'general',
      visibleIndex: 2,
      serializationProperty: 'locMarkdownText'
    },
    {
      name: 'alertType',
      category: 'general',
      default: 'none',
      visibleIndex: 3,
      choices: [
        { value: 'information', text: 'Information' },
        { value: 'error', text: 'Error' },
        { value: 'success', text: 'Success' },
        { value: 'none', text: 'None' }
      ]
    }
  ],
  function () {
    return new MarkdownTextModel('markdownText');
  },
  "question"
);
Serializer.getProperty(MARKDOWN_TEXT_QUESTION_TYPE, 'hideNumber').defaultValue = true;
Serializer.getProperty(MARKDOWN_TEXT_QUESTION_TYPE, 'titleLocation').defaultValue = 'hidden';

ReactQuestionFactory.Instance.registerQuestion(
  MARKDOWN_TEXT_QUESTION_TYPE,
  (props) => {
    return React.createElement(SurveyQuestionMarkdownText, props);
  }
);

// @ts-expect-error there are no types on settings.customIcons
settings.customIcons[`icon-${MARKDOWN_TEXT_QUESTION_TYPE}`] = 'icon-tagbox';

// Create functions that can be used in SurveyJS expressions
FunctionFactory.Instance.register("trim", (args) => {
  return args.flat().map((arg) => arg ? arg.trim() : '');
});

FunctionFactory.Instance.register("lowercase", (args) => {
  return args.flat().map((arg) => arg ? arg.toLowerCase() : '');
});
