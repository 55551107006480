import * as React from "react"
import { Slot, SlotProps } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "shared/src/utils"
import { twMerge } from "tailwind-merge";
import tw from "tailwind-styled-components";

const textVariants = cva(
  'font-normal',
  {
    variants: {
      variant: {
        default: 'text-gray-900',
        success: 'text-success-dark',
        warning: 'text-warning-dark',
        error: 'text-error-dark',
      },
      size: {
        default: 'tracking-[0.25px] text-[11.9px] md:text-[14px] lg:text-[16.1px] leading-[17px] md:leading-[20px] lg:leading-[23px]',
        2: 'tracking-[0.5px] text-[10.2px] md:text-[12px] lg:text-[13.8px] leading-[15.3px] md:leading-[18px] lg:leading-[20.7px]',
        3: 'tracking-[0.75px] text-[8.5px] md:text-[10px] lg:text-[11.5px] leading-[13.6px] md:leading-[16px] lg:leading-[18.4px]',
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof textVariants> {
  asChild?: boolean,
}

const Text = React.forwardRef<HTMLParagraphElement, ParagraphProps>(({
    className,
    variant,
    asChild = false,
    size,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : "p";

    return (
      <Comp
        className={twMerge(cn(textVariants({
          variant,
          size,
          className
        })))}
        ref={ref}
        {...props}
      />
    );
  }
);

Text.displayName = "Text";

const headingVariants = cva(
  'font-heading',
  {
    variants: {
      size: {
        1: 'text-[27.2px] leading-[34px] tracking-tighter font-medium lg:leading-[46px] lg:text-[36.8px] md:leading-10 md:text-4xl',
        2: 'leading-[27.2px] tracking-tight font-medium text-callout text-[23.8px] lg:text-[32.2px] lg:leading-[36.8px] md:text-[28px] md:leading-[32px]',
        default: 'font-semibold text-[17px] md:text-[20px] lg:text-[27.6px] leading-[20.4px] md:leading-[24px] lg:leading-[27.6px]',
        4: 'font-semibold text-callout leading-[20.4px] md:leading-[24px] lg:leading-[27.6px] text-[15.3px] md:text-[18px] lg:text-[20.7px]',
        5: 'font-medium text-callout text-[13.6px] md:text-[16px] lg:text-[18.4px] leading-[18.7px] md:leading-[22px] lg:leading-[25.3px]',
      }
    },
    defaultVariants: {
      size: "default",
    },
  }
);

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement>, VariantProps<typeof headingVariants> {
  asChild?: boolean,
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({
    className,
    asChild = false,
    size,
    ...props
  }, ref) => {
    let Comp: React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>> | string;

    switch (size) {
    case 1:
      Comp = 'h2';
      break;
    case 2:
      Comp = 'h2';
      break;
    case 4:
      Comp = 'h4';
      break;
    case 5:
      Comp = 'h5';
      break;
    default:
      Comp = 'h3';
    }

    Comp = asChild ? Slot : Comp;

    return (
      <Comp
        className={twMerge(cn(headingVariants({
          size,
          className
        })))}
        ref={ref}
        {...props}
      />
    );
  }
);

Heading.displayName = 'Heading';

const defaultHeadingSubVariant = 'leading-[20.4px] md:leading-[24px] lg:leading-[27.6px] text-[15.3px] md:text-[18px] lg:text-[20.7px] text-gray-600';
const headingSubVariants = cva(
  'italic font-medium',
  {
    variants: {
      size: {
        default: defaultHeadingSubVariant,
        1: 'text-callout leading-[23.8px] md:leading-[28px] lg:leading-[32.2px] text-[17px] md:text-[20px] lg:text-[23px]',
        2: defaultHeadingSubVariant,
        3: 'text-callout text-[13.6px] md:text-[16px] lg:text-[18.4px] leading-[18.7px] md:leading-[22px] lg:leading-[25.3px]',
        tiny: 'text-callout text-[10.2px] md:text-[12px] lg:text-[13.8px] leading-[16px]',
      }
    },
    defaultVariants: {
      size: "default",
    },
  }
);

export interface HeadingSubProps extends React.HTMLAttributes<HTMLHeadingElement>, VariantProps<typeof headingSubVariants> {
  asChild?: boolean,
}

const HeadingSub = React.forwardRef<HTMLHeadingElement, HeadingSubProps>(({
    className,
    asChild = false,
    size,
    ...props
  }, ref) => {
    let Comp: React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>> | string;

    switch (size) {
    case 1:
      Comp = 'h2';
      break;
    case 2:
      Comp = 'h2';
      break;
    case 3:
      Comp = 'h4';
      break;
    case 'tiny':
      Comp = 'h5';
      break;
    default:
      Comp = 'h3'
    }

    Comp = asChild ? Slot : Comp;

    return (
      <Comp
        className={twMerge(cn(headingSubVariants({
          size,
          className
        })))}
        ref={ref}
        {...props}
      />
    );
  }
);

HeadingSub.displayName = 'HeadingSub';

const SectionHeading = tw.h2`
  text-2xl
  font-bold
  leading-4
  text-gray-900
  sm:truncate
  sm:text-xl
  sm:tracking-tight
`;

const SectionSubHeading = tw.h3`
  text-lg
  leading-6
  text-gray-700
  sm:truncate
  sm:text-lg
  sm:tracking-tight
`;

const SectionSubSubHeading = tw.h4`
  text-sm
  font-semibold
  leading-6
  text-gray-900
`;

export { Text, Heading, HeadingSub, SectionHeading, SectionSubHeading, SectionSubSubHeading };
