import React, {ImgHTMLAttributes} from 'react'

import logo from '../logo.png';

const EasyVoteLogo = ({ alt = "EasyVote", ...rest }: ImgHTMLAttributes<HTMLImageElement>) => (
  <img alt={alt} {...rest} src={logo}  />
)

export default EasyVoteLogo

