import {ELECTION_ACTION_TYPES} from "../actions/election";
import {AnyAction} from "@reduxjs/toolkit";

const initialState = {electionId: ''};

export default function electionReducer(state = initialState, action: AnyAction) {

  switch (action.type) {
    case ELECTION_ACTION_TYPES.SET_ELECTION_ID:
      return {
        ...state,
        electionId: action.payload,
      };
    default:
      return state;
  }
}
