import {configureStore, createListenerMiddleware} from "@reduxjs/toolkit";
import rootReducer from './reducers';
import {setPollworkerSettings} from "./reducers/pollworker/settings";

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: setPollworkerSettings,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as any;
    const settingsJson = JSON.stringify(state.pollworker.settings);
    window.localStorage.setItem('pollworker:settings', settingsJson);
  },
})

const middleware: any[] = [];

if (process.env.NODE_ENV !== 'production') {
  middleware.unshift(require('redux-immutable-state-invariant').default());
}

middleware.unshift(listenerMiddleware.middleware);

export const createStore = (preloadedState = {}) => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(middleware),
  preloadedState
});

const store = createStore();

export default store;

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
