import React from 'react';
import { useIsFeatureFlagEnabled } from '../../hooks';

export default FeatureFlagCheck;

export interface FeatureFlagCheckProps {
  flag: string;
  children: React.ReactNode;
}

function FeatureFlagCheck({ flag, children }: FeatureFlagCheckProps) {
  const enabled = useIsFeatureFlagEnabled(flag);

  return (
    <>
      {enabled ? children : null}
    </>
  );
}
