import {
  CheckCircleIcon, CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { RefinementCtx, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ErrorMessage from "../../../pollworker/src/components/FormErrorMessage";
import { Link } from "wouter";
import Flexor from "./Flexor"
import EasyVoteLogo from "./EasyVoteLogo";
import Spinner from "./Spinner";
import { SESSION_STORAGE_KEYS } from "pollworker/src/constants";

const FormSchema = z.object({
  resetCode: z.string().min(6, 'Password reset code is required'),
  password1: z.string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(/[A-Z]/, { message: "Password must contain at least 1 uppercase letter" })
    .regex(/[a-z]/, { message: "Password must contain at least 1 lowercase letter" })
    .regex(/[0-9]/, { message: "Password must contain at least 1 number" })
    .regex(/[\W_]/, { message: "Password must contain at least 1 symbol" }),
  password2: z.string().min(1, 'Confirm new password is required'),
}).superRefine((data: any, ctx: RefinementCtx) => {
  if (data.password1 !== data.password2) {
    ctx.addIssue({ message: 'Passwords do not match', path: ['password2'], code: z.ZodIssueCode.custom })
  }
});

export interface ResetPasswordProps {
  startPasswordReset: (username: string) => Promise<boolean>;
  performPasswordReset: (resetCode: string, password: string, userId: string) => Promise<boolean>;
  onSuccessfulReset: (username: string) => void;
}

export default function ResetPassword({ startPasswordReset, performPasswordReset, onSuccessfulReset }: ResetPasswordProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [resentCode, setResentCode] = useState<boolean>(false);
  const [resendingCode, setResendingCode] = useState<boolean>(false);


  const userId = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.FORGOT_PASSWORD_USER_ID);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(FormSchema),
  });

  function submit(data: FieldValues) {
    if (!userId) return;

    setLoading(true);

    performPasswordReset(data.resetCode, data.password1, userId).then((success) => {
      if (success) {
        window.sessionStorage.removeItem(SESSION_STORAGE_KEYS.FORGOT_PASSWORD_USER_ID);
        setIsPasswordReset(true);
        onSuccessfulReset(userId);
        return
      }

      setErrorMessage('Something went wrong. Make sure you have the correct reset code.');
    }).finally(() => setLoading(false));
  }

  function resendCode() {
    if (!userId) return;

    setResendingCode(true);

    startPasswordReset(userId).then(() => {
      setResentCode(true);
      setValue('resetCode', '');
      setFocus('resetCode');
      setTimeout(() => setResentCode(false), 5000);
    }).finally(() => {
      setResendingCode(false);
    });
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <EasyVoteLogo
          className="mx-auto h-12 w-auto"
          alt="EasyVote"
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg shadow-md border border-gray-200/80 sm:px-10">
          {
            errorMessage ? (
              <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex items-center">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                  </div>
                </div>
              </div>
            ) : null
          }
          {
            isPasswordReset ? (
              <div className="rounded-md bg-green-50 p-4 mb-2">
                <div className="flex items-center">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  <div className="ml-3 text-green-800 flex justify-between text-sm font-medium w-full">
                    <h3>Your password has been reset!</h3>
                    <Link to='~/pollworker/login' className='font-bold'>Log in <span aria-hidden={true}>&rarr;</span></Link>
                  </div>
                </div>
              </div>
            ) : null
          }
          <div className="rounded-md bg-blue-50 p-4 mb-8">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm text-blue-700">An authorization code has been sent to your device or email address. Once you receive the code, enter the code in the text box below. Then, enter your new password and verify the password below to continue. If you did not receive an authorization code, click the Resend button to be sent a new code value.</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)} className="space-y-6 divide-y divide-gray-300" action="admin/src/components/PollworkerPublic#" method="POST">
            <div>
              <label htmlFor="resetCode" className="block text-sm font-medium leading-6 text-gray-900">
                Password reset code
              </label>
              <div className="mt-2">
                <input
                  id="resetCode"
                  type="number"
                  autoFocus={true}
                  placeholder='Reset code'
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                  {...register('resetCode')}
                />
                <ErrorMessage error={errors['resetCode']} />
              </div>
            </div>

            <div className='space-y-4 pt-4'>
              <div>
                <label htmlFor="password1" className="block text-sm font-medium leading-6 text-gray-900">
                  New password
                </label>
                <div className="mt-2">
                  <input
                    id="password1"
                    type="password"
                    autoComplete='new-password'
                    placeholder='New password'
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                    {...register('password1')}
                  />
                  <ErrorMessage error={errors.password1} />
                </div>
              </div>

              <div>
                <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                  Confirm new password
                </label>
                <div className="mt-2">
                  <input
                    id="password2"
                    type="password"
                    autoComplete='new-password'
                    placeholder='Confirm new password'
                    required
                    {...register('password2')}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage error={errors.password2} />
                </div>
              </div>
            </div>

            <Flexor className='pt-5'>
              <Flexor className='text-sm font-semibold space-x-1'>
                <button tabIndex={-1} type='button' onClick={resendCode}>
                  Resend reset code
                </button>
                <div className='scale-75'><Spinner show={resendingCode} /></div>
                {resentCode ? <CheckIcon className="h-4 w-4 text-green-500"/> : null}
              </Flexor>
              <button
                type="submit"
                disabled={loading}
                className="space-x-2 flex-shrink flex items-center justify-center rounded-md bg-ev-red py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-ev-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ev-red disabled:bg-ev-red/50"
              >
                <Spinner show={loading} light />
                <span>Reset password</span>
              </button>
            </Flexor>
          </form>
        </div>
      </div>
    </div>
  )
}
