import React, { ErrorInfo, ReactNode, useState } from "react";
import {SectionSubHeading} from "./SectionHeading";
import logo404Left from "admin/src/logo_404_left.png";
import logov from "admin/src/logo-v.png";
import logo404Right from "admin/src/logo_404_right.png";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {Button} from "shared/src/components/ui";
import Flexor from "./Flexor";

const ErrorPage = ({onShowErrorDetails}: { onShowErrorDetails: () => void }) => {
  const [showingErrorDetails, setShowingErrorDetails] = useState(false);
  const [hideFunnyBizness, setHideFunnyBizness] = useState(false);

  function showErrorDetails() {
    if (onShowErrorDetails) {
      onShowErrorDetails();
      setShowingErrorDetails(true);
      setTimeout(() => setHideFunnyBizness(true), 3000);
    }
  }

  function clearLocalAndReload() {
    if (window.confirm('Do you want to clear Local Storage and reload the page?')) {
      window.localStorage.clear();
      window.location.reload();
    }
  }

  return (
    <>
      <h1 className="text-center font-bold text-gray-700" style={{fontSize: '10em'}}>Uh oh!</h1>
      <Flexor className="-mt-7">
        <Button variant='secondary' className="mx-auto" onClick={() => window.location.reload()}>
          <ArrowPathIcon className="h-5 w-5" />
          <span>Reload</span>
        </Button>
      </Flexor>
      <SectionSubHeading className="mt-3 text-center">We've run into a snag. Please let us know if this issue persists!</SectionSubHeading>
      {
        !hideFunnyBizness ? (
          <>
            <img onDoubleClick={showErrorDetails} alt="Jumbled up pile of Easy in 'EasyVote'" className={`${showingErrorDetails ? 'shake' : ''} absolute bottom-0 left-1/6 w-1/3 sm:left-1/4 sm:w-1/6`} src={logo404Left} />
            <figure className="swing absolute bottom-20 w-[15%] left-[45%] scale-50 rotate-[35deg]">
              <img alt="The V in EasyVote swinging from it's tail" width={100} src={logov} />
            </figure>
            <img onDoubleClick={() => clearLocalAndReload()} alt="Jumbled up pile of Vote in 'EasyVote'" className="absolute bottom-0 right-0 w-1/3 sm:right-1/4 sm:w-1/6" src={logo404Right} />
            <span className="text-ev-red text-3xl absolute -bottom-3 right-0 sm:right-1/4 rotate-45">®</span>
          </>
        ) : null
      }
    </>
  )
};

type ErrorState = {
  hasError: boolean,
  showErrorDetails: boolean,
  error?: Error,
}

class ErrorBoundary extends React.Component<{children: ReactNode}, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false, showErrorDetails: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.setState({hasError: true, error});
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorPage onShowErrorDetails={() => this.setState({showErrorDetails: true})}/>
          {
            (process.env.NODE_ENV === 'development' || this.state.showErrorDetails) && (
              <div className="w-3/4 mx-auto">
                <SectionSubHeading className="text-ev-red">Error Details</SectionSubHeading>
                <br/>
                <pre>{this.state.error?.message}</pre>
                <br/>
                <pre>{this.state.error?.stack}</pre>
              </div>
            )
          }
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
