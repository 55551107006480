import React, { createRef, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { getPartiesForCustomer, getVotingLocationsForElection } from "admin/src/fetchers";
import { useDispatch, useSelector } from "react-redux";
import { Party } from 'admin/src/types';
import { Flexor } from "shared/src/components";
import { ArrowPathIcon, ArrowsRightLeftIcon, ChevronLeftIcon, FunnelIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { PartiesByCode, PartiesById } from "./pollworkerVotingLocationDetailsUtils";
import { Button, Dropdown, Popover, Switch } from "shared/src/components/ui";
import PollworkerDetails from "./PollworkerDetailsPanel";
import useSelectedPollworker from "../../../hooks/useSelectedPollworker";
import { AppState } from "../../../reducers";
import PollworkerVLTraditionalScheduler from "./PollworkerVLTraditionalScheduler";
import { Link, useParams } from "wouter";
import { classNames } from "shared/src/utils";
import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { clearFilters, setAllVotingLocations, setHidePastDates, setLoadingAllVotingLocations, setOnlyShowEarlyVotingLocations, setPartiesByCode, setPartiesById, setScrollToLocation, setShowOnlyElectionDates } from "../../../reducers/pollworker/scheduler";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { useElectionDates } from "admin/src/hooks/useElectionDates";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import PollworkerVLSwimlaneScheduler from "./PollworkerVLSwimlaneScheduler";

type ViewType = 'traditional' | 'swimlanes';

function VotingLocationScrollSelector() {
  const scrollToLocation = useAppSelector(state => state.pollworker.scheduler.scrollToLocation);
  const filteredVotingLocations = useAppSelector(state => state.pollworker.scheduler.filteredVotingLocations);
  const {viewType} = useParams<{viewType: ViewType}>();
  const dispatch = useAppDispatch();

  const votingLocationScrollToOptions = useMemo(() => {
    return filteredVotingLocations.map((vl) => ({
      label: vl.locationName,
      value: vl.locationCode
    }))
  }, [filteredVotingLocations]);

  if (viewType !== 'swimlanes') return null;

  return (
    <Dropdown
      className="p-1"
      value={scrollToLocation}
      defaultValue={scrollToLocation}
      options={votingLocationScrollToOptions}
      placeholder="Scroll to Location"
      emptyPlaceholder="Scroll to Location"
      onChange={(event) => dispatch(setScrollToLocation(event.target.value))}
    />
  );
}

export function PollworkerVotingLocationDetails({ reload, loading }: { reload: () => void, loading: boolean }) {
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const { filters: schedulerFilters } = useAppSelector(state => state.pollworker.scheduler);
  const [view, setView] = useState<ViewType>('traditional'); // swimlanes
  const electionDates = useElectionDates();
  const pollworker = useSelectedPollworker();
  const {viewType} = useParams<{viewType: ViewType}>();
  const dispatch = useDispatch();

  const customerId = window.sessionStorage.getItem('customerId');
  const { hidePastDates, onlyShowEarlyVotingLocations, showOnlyElectionDates, } = schedulerFilters;
  const hasFiltersEnabled = Object.values(schedulerFilters).some(f => f);

  useEffect(() => {
    if (!viewType) return setView('traditional');

    setView(viewType);
  }, [viewType]);

  useEffect(() => {
    if (!moduleInfo?.ElectionId) return;

    loadVotingLocations(moduleInfo?.ElectionId);
  }, [moduleInfo]);

  useEffect(() => {
    if (!customerId) return;

    getPartiesForCustomer(customerId).then((resp) => {
      const partiesById = resp.reduce((acc: PartiesById, party: Party) => {
        acc[party.id] = party;
        return acc;
      }, {});

      const partiesByCode = resp.reduce((acc: PartiesByCode, party: Party) => {
        acc[party.partyCode] = party;
        return acc;
      }, {});

      dispatch(setPartiesById(partiesById))
      dispatch(setPartiesByCode(partiesByCode))
    });
  }, [customerId]);

  async function loadVotingLocations(electionId: string) {
    if (!moduleInfo || !moduleInfo.ElectionId) return null;

    dispatch(setLoadingAllVotingLocations(true));

    const votingLocations = await getVotingLocationsForElection(electionId).then((resp) => {
      return JSON.parse(atob(resp.data));
    }).finally(() => {
      dispatch(setLoadingAllVotingLocations(false));
    });

    dispatch(setAllVotingLocations(votingLocations));
  }

  if (!electionDates || !moduleInfo?.Election) return null;

  return (
    <div className="h-full flex flex-col justify-between">
      <Flexor className="bg-white border-b border-gray-300 text-sm divide-x divide-gray-300">
        <div className="flex items-center justify-start divide-x divide-gray-300">
          <Link to="~/admin/pollworker">
            <Flexor className="space-x-2 shrink-0 h-full px-2.5 cursor-pointer">
              <ChevronLeftIcon className="size-4"/>
              <span>Pollworker Manager</span>
            </Flexor>
          </Link>
          <Flexor className="space-x-2 h-full px-2.5">
            <VotingLocationScrollSelector />
            <Popover>
              <PopoverTrigger>
                <Flexor className='space-x-2 relative'>
                  <span>Filters</span>
                  <FunnelIcon className='size-5' />
                  {hasFiltersEnabled ? <span className='size-2 rounded-full bg-ev-red absolute -top-1 -right-1'></span> : null}
                </Flexor>
              </PopoverTrigger>
              <PopoverContent className='mt-4 rounded z-50 relative min-w-[300px] overflow-y-auto overflow-x-hidden p-3 bg-white shadow'>
                <div>
                  <Flexor className='mb-3'>
                    <SectionSubSubHeading>Filters</SectionSubSubHeading>
                    <Button variant='quaternary' onClick={() => dispatch(clearFilters())}>Clear</Button>
                  </Flexor>
                  <div className='space-y-4'>
                    <Flexor className='shrink-0'>
                      <span>Hide past dates</span>
                      <Switch onCheckedChange={(checked) => dispatch(setHidePastDates(checked))} checked={hidePastDates} defaultChecked={false}/>
                    </Flexor>
                    <Flexor className='shrink-0'>
                      <span>Show only election dates</span>
                      <Switch data-testid='show-only-election-dates-switch' onCheckedChange={(checked) => dispatch(setShowOnlyElectionDates(checked))} checked={showOnlyElectionDates} defaultChecked={false}/>
                    </Flexor>
                    <Flexor className='shrink-0'>
                      <span>Only show Early Voting Locations</span>
                      <Switch data-testid='only-show-early-voting-loc-switch' onCheckedChange={(checked) => dispatch(setOnlyShowEarlyVotingLocations(checked))} checked={onlyShowEarlyVotingLocations} defaultChecked={false} />
                    </Flexor>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            <div>
              <Button size="sm" variant="tertiary" onClick={reload}>
                <ArrowPathIcon className={classNames(loading ? 'animate-spin' : '', 'size-4')}/>
                <span>Refresh</span>
              </Button>
            </div>
          </Flexor>
        </div>
        <div className="flex items-center justify-start divide-x divide-gray-300">
          <Link to='/scheduler/traditional'>
            <Flexor className="space-x-2 h-full py-2 px-2.5">
              <QueueListIcon className="size-4"/>
              <span>Traditional</span>
            </Flexor>
          </Link>
          <Link to='/scheduler/swimlanes'>
            <Flexor className="space-x-2 h-full py-2 px-2.5">
              <ArrowsRightLeftIcon className="rotate-90 size-4"/>
              <span>Swimlanes</span>
            </Flexor>
          </Link>
        </div>
      </Flexor>
      <div className='w-full h-full flex overflow-hidden'>
        <div className='flex-[66.6_1_0%] overflow-x-auto'>
          {
            view === 'swimlanes' ? (
              <PollworkerVLSwimlaneScheduler />
            ) : (
              <PollworkerVLTraditionalScheduler />
            )
          }
        </div>
        {
          pollworker ? (
            <div className='flex-[33.3_1_0%] bg-white border-l border-gray-300'>
              <div className='h-full overflow-y-auto'>
                <PollworkerDetails readOnly/>
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  );
}
