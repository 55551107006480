import * as React from "react";

import { cn } from "shared/src/utils";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ScreenReaderHidden } from "../Accessibility";

export const inputVariants = cva(
  // flex items-center w-full
  'rounded-none relative',
  {
    variants: {
      variant: {
        default: `w-full bg-white rounded-md border-0 px-3 py-1.5 text-[14px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:!ring-ev-red sm:leading-6`,
        new: `
          rounded-t
          text-action
          border-[0.5px]
          border-b-2
          border-action
          bg-white
          p-0
          py-1.5
          px-3
          text-sm
          focus:ring-0
          file:border-0
          file:bg-transparent
          file:text-sm
          file:font-medium
          placeholder:text-action
          hover:border-state-hover
          focus:shadow-none
          focus-visible:shadow-none
          focus-visible:text-neutral
          focus-visible:border-action-info
          focus-visible:outline-none
          disabled:cursor-not-allowed
          disabled:border-state-disabled
          disabled:text-state-disabled
          disabled:placeholder:text-state-disabled
          invalid:border-error
          invalid:text-error
          invalid:placeholder:text-error
        `,
      },
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({
    variant,
    className,
    type,
    ...props
  }, ref) => {
    return (
      <div className='relative w-full'>
        <input
          type={type}
          ref={ref}
          className={twMerge(cn(inputVariants({ variant, className }), props['aria-invalid'] ? 'pr-7' : ''))}
          {...props}
        />
        <div className={cn(props['aria-invalid'] ? 'absolute' : 'hidden', "pointer-events-none right-1 inset-y-0.5")}>
          <ScreenReaderHidden>
            <ExclamationCircleIcon className="h-5 w-5 text-error" />
          </ScreenReaderHidden>
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
