import { InventoryEquipTypeSummary } from 'admin/src/types/Inventory';
import { AppState } from 'admin/src/store';

export const selectInventoryEquipTypeSummariesForCustomer = (state: AppState, customerId: string): InventoryEquipTypeSummary[] | null => {
  if (!!state.inventory.inventoryEquipment.equipTypeSummaries[customerId]) {
    return state.inventory.inventoryEquipment.equipTypeSummaries[customerId];
  }

  return null;
};
