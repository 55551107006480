import Tippy from '@tippyjs/react';
import {ReactElement, ReactNode} from "react";

const TippyContent = ({classes, children}: {classes?: string, children: ReactNode}) => (
  <div className={`bg-black text-white bg-opacity-80 shadow-md rounded-lg p-3 block ${classes}`}>
    {children}
  </div>
);

export const SimpleTippyContent = ({disabled = false, children, content}: {disabled?: boolean, children: ReactElement, content: string}) => {
  if (disabled) return children;

  return (
    <Tippy content={(
      <TippyContent>{content}</TippyContent>
    )}>
      {children}
    </Tippy>
  )
}

export default TippyContent;

