import tw from "tailwind-styled-components";

import * as React from "react";

import { cn } from "shared/src/utils";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ScreenReaderHidden } from "../Accessibility";

const textareaVariants = cva(
  'relative',
  {
    variants: {
      variant: {
        default: `
          block
          w-full
          text-[14px]
          rounded-md
          border-0
          text-gray-900
          shadow-sm
          ring-1
          ring-inset
          ring-gray-600
          disabled:ring-gray-400
          placeholder:text-gray-500
          focus:ring-2
          focus:ring-inset
          focus:ring-ev-red
          sm:py-1.5
          sm:text-sm
          sm:leading-6
        `,
        new: `
          flex
          w-full
          rounded-t
          text-action
          border
          border-b-2
          border-action
          bg-white
          text-sm
          file:border-0
          file:bg-transparent
          file:text-sm
          file:font-medium
          focus:shadow-none
          focus:ring-0
          focus-visible:outline-none
          placeholder:text-action
          disabled:placeholder:text-state-disabled
          disabled:cursor-not-allowed
          disabled:text-state-disabled
          disabled:border-state-disabled
          invalid:border-error
          invalid:text-error
          invalid:placeholder:text-error
          invalid:text-error
        `
      },
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement>, VariantProps<typeof textareaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({
    variant,
    className,
    ...props
  }, ref) => {
    return (
      <div className='relative'>
        <textarea
          className={twMerge(cn(textareaVariants({ variant, className }), props['aria-invalid'] ? 'pr-7' : ''))}
          ref={ref}
          {...props}
        />
        {
          props['aria-invalid'] ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex flex-col items-center pt-2 pr-2">
              <ScreenReaderHidden>
                <ExclamationCircleIcon className="h-5 w-5 text-error" aria-hidden="true" />
              </ScreenReaderHidden>
            </div>
          ) : null
        }
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
