import {fetchApi} from 'shared/src/fetchers';
import {
  Deployment,
  DeploymentDashboardEquipmentSummary,
  InventoryEquipment,
  InventoryEquipTypeSummary
} from 'admin/src/types/Inventory';
import { DataburstResponse } from 'admin/src/types/DataburstResponse';

export const fetchActiveDeployments = async (): Promise<DataburstResponse<Deployment[]>> => {
  const response = await fetchApi('/api/DeploymentApi/LoadActiveDeploymentsForCustomer', {
    method: 'POST'
  });

  const responseBody = await response.json();

  return {
    ...responseBody,
    data: responseBody.data ? JSON.parse(atob(responseBody.data)) : []
  } as DataburstResponse<Deployment[]>;
};

export const fetchActiveDeploymentsForCustomer = async (customerId: string): Promise<DataburstResponse<Deployment[]>> => {
  const response = await fetchApi(`/api/DeploymentApi/LoadActiveDeploymentsForCustomer/${customerId}`, {
    method: 'POST'
  });

  const responseBody = await response.json();

  return {
    ...responseBody,
    data: responseBody.data ? JSON.parse(atob(responseBody.data)) as Deployment[] : []
  } as DataburstResponse<Deployment[]>;
};

export const downloadInventorySealReport = async (deploymentId: string) => {
  const response = await fetchApi(`/api/ReportsApi/inventory/seals?deploymentId=${deploymentId}`, {
    method: 'GET'
  });

  return await response.blob();
};

export const fetchDeploymentEquipmentSummary = async (customerId: string, deploymentId: string): Promise<DeploymentDashboardEquipmentSummary[]> => {
  const response = await fetchApi(`/di-api/inventory/deployments/${deploymentId}/summaries/${customerId}`, {
    method: 'GET'
  }).then(httpResponse => httpResponse.json());

  return response;
};

export const fetchEquipmentTypeSummaries = async (customerId: string): Promise<InventoryEquipTypeSummary[]> => {
  return await fetchApi(`/di-api/inventory/InventoryEquipments/customer/${customerId}/counts`, {
    method: 'GET'
  }).then(httpResponse => httpResponse.json());
};

export const fetchInventoryEquipmentByType = async (customerId: string, equipTypeId: string): Promise<InventoryEquipment[]> => {
  const mapDateString = (dateString: string) => {
    const justDate = dateString.split('T')[0];

    if (justDate === '0001-01-01') {
      return '';
    }

    return justDate;
  };

  return await fetchApi(`/di-api/inventory/InventoryEquipments/customer/${customerId}/equipType/${equipTypeId}`, {
    method: 'GET'
  }).then(httpResponse => httpResponse.json())
    .then((equipment: InventoryEquipment[]) => equipment.map(ie => {
      ie.dateRetired = mapDateString(ie.dateRetired);
      ie.datePurchased = mapDateString(ie.datePurchased);
      ie.dateLastTested = mapDateString(ie.dateLastTested);

      return ie;
    }));
};
