import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FeatureFlagCheck } from 'admin/src/types/FeatureFlagCheck';


export type FeatureFlagState = {
  [flag: string]: FeatureFlagCheck
}

const initialFeatureFlagState: FeatureFlagState = {};

const slice = createSlice({
  name: 'featureFlags',
  initialState: initialFeatureFlagState,
  reducers: {
    setFeatureFlagCheck: (state, action: PayloadAction<FeatureFlagCheck>) => {
      state[action.payload.flag] = action.payload;
    },
    clearFeatureFlagCheck: (state, action: PayloadAction<FeatureFlagCheck>) => {
      delete state[action.payload.flag];
    }
  }
})

export default slice.reducer;

export const {
  setFeatureFlagCheck,
  clearFeatureFlagCheck
} = slice.actions;
