import React, {useMemo} from 'react';

export default function DateFormatter({dateFormats, dateString, withTime = true}: {dateFormats?: Intl.DateTimeFormatOptions, dateString?: string, withTime?: boolean}) {
  const formattedDate = useMemo(() => {
    const options: Intl.DateTimeFormatOptions = dateFormats || {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: undefined,
      minute: undefined,
    };

    if (withTime && !dateFormats) {
      options.hour = 'numeric';
      options.minute = 'numeric';
    }

    if (!dateString) return null;

    return new Intl.DateTimeFormat("en-US", options)
      .format(new Date(dateString));
  }, [withTime, dateFormats, dateString]);

  return (<>{formattedDate}</>);
}
