import React, {useEffect, useMemo, useState} from 'react';

import {useAppDispatch, useAppSelector} from "admin/src/hooks";
import {selectSelectedCounty, selectCustomersFromOrderConfigs} from "admin/src/selectors";
import {setSelectedCounty} from "../../actions";
import {getCountyConfigs} from "../../fetchers";
import {setOrderConfigs} from "../../actions/admin/order";
import {Combobox} from "shared/src/components/ui/Combobox";

export default CustomerDropdown;

export interface CustomerDropdownProps {
  className?: string | undefined;
}

function CustomerDropdown({ className }: CustomerDropdownProps) {
  const [countiesLoading, setCountiesLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const selectedCounty = useAppSelector(selectSelectedCounty);
  const counties = useAppSelector(selectCustomersFromOrderConfigs);

  const onSelectedCountyChange = (newCountyId: string) => {
    dispatch(setSelectedCounty(newCountyId));
  };

  const countyOptions = useMemo(() => {
    if (!counties.length) return [];

    return counties.map((county) => ({
      label: county.customerName,
      value: county.id
    }));
  }, [counties]);

  useEffect(() => {
    if (counties && counties.length > 0) {
      return;
    }

    setCountiesLoading(true);
    getCountyConfigs()
      .then(countyConfigs => {
        dispatch(setOrderConfigs(countyConfigs.data));
      })
      .finally(() => setCountiesLoading(false));
  }, []);

  useEffect(() => {
    if (!selectedCounty && counties && counties.length > 0) {
      onSelectedCountyChange(counties[0].id);
    }
  }, [selectedCounty, counties]);

  return (
    <Combobox
      selectedValue={selectedCounty}
      placeholder={countiesLoading ? 'Loading...' : 'No sub-customers found...'}
      options={countyOptions}
      onValueChange={onSelectedCountyChange}
    />
  )
}
