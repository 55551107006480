import React from "react";
import { Alert } from "./ui";
import { Converter } from "showdown";
import {
  Question,
} from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

export const POLITICAL_PARTY_QUESTION_TYPE = 'party'
export const MARKDOWN_TEXT_QUESTION_TYPE = "markdowntext";

export class MarkdownTextModel extends Question {
  constructor(name: string) {
    super(name);
    this.createLocalizableString('markdownText', this);
  }
  getType() {
    return MARKDOWN_TEXT_QUESTION_TYPE;
  }
  get alertType() {
    return this.getPropertyValue('alertType');
  }
  set alertType(val) {
    this.setPropertyValue('alertType', val);
  }
  get markdownText() {
    return this.getLocalizableStringText('markdownText');
  }
  set markdownText(val) {
    this.setLocalizableStringText('markdownText', val);
  }
  get locMarkdownText() {
    return this.getLocalizableString('markdownText');
  }
}

export class SurveyQuestionMarkdownText extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase;
  }

  componentDidMount() {
    super.componentDidMount();

    (document.querySelectorAll('.markdown-text a') as NodeListOf<HTMLAnchorElement>).forEach((el: HTMLAnchorElement) => {
      el.target = '_blank';
    });
  }

  renderMarkdown() {
    const converter = new Converter();

    return (
      <div className='markdown-text' dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.question.locMarkdownText.text) }}></div>
    )
  }

  renderElement() {
    if (this.question.alertType === 'none') return this.renderMarkdown();

    return (
      <Alert variant={this.question.alertType}>
        {this.renderMarkdown()}
      </Alert>
    );
  }
}
