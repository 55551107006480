import dayjs from "dayjs";
import {patternFormatter} from "react-number-format";

export function dateFormatter(params: any) {
  if (!params.value) return '';
  return dayjs(params.value).format('MM-DD-YYYY');
}

export function phoneNumberFormatter(params: any) {
  if (!params.value) return '';
  // remove none digits and remove country code by selecting the last 10 digits and replacing any preceding tokens with an empty string
  const value = params.value.replaceAll(/[^\d]/g, '').replaceAll(/(.*)(\d{10})/g, '$2');
  return patternFormatter(value, {format: '(###) ###-####', mask: '_'});
}

export const phoneRegEx= /^((\(\d{3}\))|(\d{3}))(\s|-|\.)?\d{3}(-|.\s)?\d{4}$/;