import { VotingLocation } from 'admin/src/types';
import Tippy from "@tippyjs/react";
import { TippyContent } from "shared/src/components";
import { BugAntIcon, GlobeAsiaAustraliaIcon, SunIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function VotingLocationAdorners({votingLocation}: {votingLocation: VotingLocation}) {
  return (
    <>
      {
        votingLocation.usedForEarlyVoting ? (
          <Tippy content={<TippyContent>Early voting location</TippyContent>}>
            <div className='size-5'>
              <SunIcon data-testid='early-voting-location-icon' className='size-5' />
            </div>
          </Tippy>
        ) : null
      }
      {
        votingLocation.outOfCounty ? (
          <Tippy content={<TippyContent>Out of County</TippyContent>}>
            <GlobeAsiaAustraliaIcon data-testid='out-of-county-location-icon' className='size-5' />
          </Tippy>
        ) : null
      }
      {
        process.env.NODE_ENV === 'development' ? (
          <BugAntIcon onClick={(event) => {
            console.log(votingLocation);
            event.stopPropagation();
          }} className='size-4' />
        ) : null
      }
    </>
  );
};
