import Flexor from "./Flexor"
import {BuildingStorefrontIcon, ShoppingBagIcon, ShoppingCartIcon} from "@heroicons/react/24/outline";

export default function Loading({loadingMessage, className}: {loadingMessage: string, className?: string}) {
  return (
    <div className={className}>
      <Flexor justify="center" items="end" className="space-x-2">
        <ShoppingCartIcon className="h-7 w-7 animate-pulse -rotate-12" />
        <BuildingStorefrontIcon className="h-10 w-10 animate-pulse" />
        <ShoppingBagIcon className="h-7 w-7 animate-pulse rotate-12" />
      </Flexor>
      <p className="text-center text-sm text-gray-800 mt-3">{loadingMessage}</p>
    </div>
  )
}
