import React, {useState, useEffect} from 'react';
import {useHarmonicIntervalFn} from "react-use";

export interface TimeclockProps {
    scheduledTimeUTC: number | undefined;
    isClockedIn: boolean;
}

export default function Timeclock({scheduledTimeUTC, isClockedIn}: TimeclockProps) {
    const [displayTime, setDisplayTime] = useState<string>('');
    const [isLate, setIsLate] = useState<boolean>(false);

    useEffect(() => recalculateDisplayTime(), []);

    useHarmonicIntervalFn(() => recalculateDisplayTime(), 1000);

    function recalculateDisplayTime() {
        const scheduledTimeObject = new Date(scheduledTimeUTC ? scheduledTimeUTC : Date.now());

        if (isClockedIn) {
            setIsLate(false);
            setDisplayTime(generateDisplayText(new Date(Date.now()).getTime(), scheduledTimeObject.getTime()));
        } else {
            if (scheduledTimeObject.getTime() < Date.now()) {
                setIsLate(true);
                setDisplayTime('00:00:00');
            } else {
                setDisplayTime(generateDisplayText(scheduledTimeObject.getTime(), new Date(Date.now()).getTime()));
            }
        }
    }

    function generateDisplayText(startTime: number, endTime: number) {
        const timeDiff = startTime - endTime;
        const diffSeconds = Math.floor(timeDiff / 1000);
        const seconds = diffSeconds % 60;
        const diffMinutes = Math.floor(diffSeconds / 60);
        const hours = Math.floor(diffMinutes / 60);
        const minutes = diffMinutes % 60;

        return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
    }

    return (
        <div>
            {displayTime}
        </div>
    );
};
