export interface UsState {
  state: string,
  abbr: string,
}

export const STATES: UsState[] = [
  {state: 'ALABAMA', abbr: 'AL'},
  {state: 'ALASKA', abbr: 'AK'},
  {state: 'AMERICAN SAMOA', abbr: 'AS'},
  {state: 'ARIZONA', abbr: 'AZ'},
  {state: 'ARKANSAS', abbr: 'AR'},
  {state: 'CALIFORNIA', abbr: 'CA'},
  {state: 'COLORADO', abbr: 'CO'},
  {state: 'CONNECTICUT', abbr: 'CT'},
  {state: 'DELAWARE', abbr: 'DE'},
  {state: 'DISTRICT OF COLUMBIA', abbr: 'DC'},
  {state: 'FLORIDA', abbr: 'FL'},
  {state: 'GEORGIA', abbr: 'GA'},
  {state: 'GUAM', abbr: 'GU'},
  {state: 'HAWAII', abbr: 'HI'},
  {state: 'IDAHO', abbr: 'ID'},
  {state: 'ILLINOIS', abbr: 'IL'},
  {state: 'INDIANA', abbr: 'IN'},
  {state: 'IOWA', abbr: 'IA'},
  {state: 'KANSAS', abbr: 'KS'},
  {state: 'KENTUCKY', abbr: 'KY'},
  {state: 'LOUISIANA', abbr: 'LA'},
  {state: 'MAINE', abbr: 'ME'},
  {state: 'MARYLAND', abbr: 'MD'},
  {state: 'MASSACHUSETTS', abbr: 'MA'},
  {state: 'MICHIGAN', abbr: 'MI'},
  {state: 'MINNESOTA', abbr: 'MN'},
  {state: 'MISSISSIPPI', abbr: 'MS'},
  {state: 'MISSOURI', abbr: 'MO'},
  {state: 'MONTANA', abbr: 'MT'},
  {state: 'NEBRASKA', abbr: 'NE'},
  {state: 'NEVADA', abbr: 'NV'},
  {state: 'NEW HAMPSHIRE', abbr: 'NH'},
  {state: 'NEW JERSEY', abbr: 'NJ'},
  {state: 'NEW MEXICO', abbr: 'NM'},
  {state: 'NEW YORK', abbr: 'NY'},
  {state: 'NORTH CAROLINA', abbr: 'NC'},
  {state: 'NORTH DAKOTA', abbr: 'ND'},
  {state: 'NORTHERN MARIANA IS', abbr: 'MP'},
  {state: 'OHIO', abbr: 'OH'},
  {state: 'OKLAHOMA', abbr: 'OK'},
  {state: 'OREGON', abbr: 'OR'},
  {state: 'PENNSYLVANIA', abbr: 'PA'},
  {state: 'PUERTO RICO', abbr: 'PR'},
  {state: 'RHODE ISLAND', abbr: 'RI'},
  {state: 'SOUTH CAROLINA', abbr: 'SC'},
  {state: 'SOUTH DAKOTA', abbr: 'SD'},
  {state: 'TENNESSEE', abbr: 'TN'},
  {state: 'TEXAS', abbr: 'TX'},
  {state: 'UTAH', abbr: 'UT'},
  {state: 'VERMONT', abbr: 'VT'},
  {state: 'VIRGINIA', abbr: 'VA'},
  {state: 'VIRGIN ISLANDS', abbr: 'VI'},
  {state: 'WASHINGTON', abbr: 'WA'},
  {state: 'WEST VIRGINIA', abbr: 'WV'},
  {state: 'WISCONSIN', abbr: 'WI'},
  {state: 'WYOMING', abbr: 'WY'},
]

export const STATES_BY_ABBR: { [key:string]: UsState } = STATES.reduce((acc, stateData: UsState) => {
  return {
    ...acc,
    [stateData.abbr]: stateData,
  }
}, {});
