import {
  Precinct,
  RoleInfo,
  Schedule,
  VotingLocation
} from 'admin/src/types';
import React, {useEffect, useMemo, useState} from "react";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import {useSelector} from "react-redux";
import {AppState} from "admin/src/reducers";
import dayjs, {Dayjs} from "dayjs";
import {getPollworkerSettings} from "admin/src/fetchers";
import {classNames} from "shared/src/utils/classNames";
import { CalendarDaysIcon, CalendarIcon } from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components"
import { Dropdown } from "shared/src/components/ui";
import {Button} from "shared/src/components/ui";
import {DayPicker, DateRange, DayContent, DayContentProps} from "react-day-picker";

import 'react-day-picker/dist/style.css';
import './CreateWorkTimeModal.css';

function DayContentRenderer(props: DayContentProps) {
  const dateString = dayjs(props.date).format('YYYY-MM-DD');

  return (
    <div data-testid={dateString}>
      <DayContent {...props} />
    </div>
  );
}

function getDateRangeDaysCount(dayRange: DateRange) {
  return dayjs(dayRange.to).diff(dayjs(dayRange.from), 'days');
}

export default function CreateWorkTimeEditor({
  selectedElectionId,
  scheduleToEdit,
  onAdd,
  precincts,
  votingLocations,
  pollworkerRoles,
  allVotingLocations_WithoutElectionData,
}: {
  selectedElectionId?: string,
  scheduleToEdit?: Partial<Schedule>,
  precincts: Precinct[],
  pollworkerRoles: RoleInfo[],
  onAdd: (schedules: Partial<Schedule>[]) => void,
  votingLocations: VotingLocation[],
  allVotingLocations_WithoutElectionData: VotingLocation[],
}) {
  const [dateType, setDateType] = useState<'single' | 'range'>('single');
  const [assignTo, setAssignTo] = useState<'precinct' | 'votingLocation' | 'default'>('precinct');
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState<string>();
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [dateTypeDisabled, setDateTypeDisabled] = useState<boolean>(false);
  const [dayRange, setDayRange] = useState<DateRange | undefined>();
  const [dayMultiple, setDayMultiple] = useState<Date[] | undefined>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const pollworker = useSelectedPollworker();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);

  useEffect(() => {
    if (!scheduleToEdit) return;

    const workDate = dayjs(scheduleToEdit.workDate)
    const workDateFormatted = workDate.format('YYYY-MM-DD');
    const startTime = dayjs(`${workDateFormatted} ${scheduleToEdit.startTime}`).format('HH:mm');
    const endTime = dayjs(`${workDateFormatted} ${scheduleToEdit.endTime}`).format('HH:mm');

    // @ts-ignore
    setAssignTo(scheduleToEdit.keyPrecinctId ? 'precinct' : 'votingLocation');
    setDateTypeDisabled(true);
    setDateType('single');
    setDayMultiple([workDate.toDate()]);
    setStartTime(startTime);
    setEndTime(endTime);
    setSelectedRoleId(scheduleToEdit.keyRoleId);
    setSelectedWorkplaceId(scheduleToEdit.keyPrecinctId || scheduleToEdit.keyVotingLocationId);
  }, [scheduleToEdit]);

  useEffect(() => {
    if (assignTo !== 'default') return;

    setSelectedWorkplaceId(pollworker.pollworkerInfo.defaultAssignedLocationId);
  }, [assignTo]);

  useEffect(() => {
    if (!pollworker || !moduleInfo) return;

    if (!scheduleToEdit) {
      getPollworkerSettings(pollworker.userInfo.keyCustomerId).then((resp) => {
        const settings = JSON.parse(atob(resp.data));

        const {defaultWorkStartTime, defaultWorkEndTime} = settings;
        const startTime = dayjs(defaultWorkStartTime.split('+')[0]).format('HH:mm');
        const endTime = dayjs(defaultWorkEndTime.split('+')[0]).format('HH:mm');

        setStartTime(startTime);
        setEndTime(endTime);
      });
    }
  }, [moduleInfo, pollworker, scheduleToEdit]);

  const roleOptions = useMemo(() => {
    return pollworkerRoles.map((e) => ({value: e.id, label: e.roleName || ''}));
  }, [pollworkerRoles]);

  function saveAndClose() {
    let modifiedAssignedTo = assignTo;

    let range: Dayjs[] = (dayMultiple || []).map(dayjs);

    if (dateType === 'range' && !!dayRange) {
      const days = getDateRangeDaysCount(dayRange);
      range = new Array(days + 1).fill(0).map((_fill, dateIndex) => dayjs(dayRange.from).add(dateIndex, 'day'));
    }

    if (assignTo === 'default') {
      const defaultPrecinct = precincts.find((p) => p.id === selectedWorkplaceId || p.baseId === selectedWorkplaceId);
      const defaultVotingLocation = !defaultPrecinct && allVotingLocations_WithoutElectionData.find((vl) => vl.id === selectedWorkplaceId);

      if (defaultPrecinct) modifiedAssignedTo = 'precinct';
      else if (defaultVotingLocation) modifiedAssignedTo = 'votingLocation';
    }

    const newSchedules = range.map((dayDate) => {
      const workDate = dayDate.toDate();

      return {
        id: scheduleToEdit?.id,
        workDate: workDate.toISOString(),
        startTime: dayjs(`${workDate.toDateString()} ${startTime}`).format('hh:mm A'),
        endTime: dayjs(`${workDate.toDateString()} ${endTime}`).format('hh:mm A'),
        keyRoleId: selectedRoleId,
        keyEVUserId: pollworker.pollworkerInfo.keyEVUserId,
        keyElectionId: selectedElectionId,
        keyPrecinctId: modifiedAssignedTo === 'precinct' ? selectedWorkplaceId : null,
        keyVotingLocationId: modifiedAssignedTo === 'votingLocation' ? selectedWorkplaceId : null,
      } as Partial<Schedule>;
    });

    onAdd(newSchedules);
  }

  const hasMeta = !!startTime && !!endTime && !!selectedRoleId && !!selectedWorkplaceId;
  const canAddSingle = !!dayMultiple?.length && hasMeta;
  const canAddRange = hasMeta && !!dayRange;

  let addDisabled = dateType === 'single' ? !canAddSingle : !canAddRange;

  return (
    <div className='px-2'>
      <div>
        <div className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Type</div>
        <span className="isolate mt-2 inline-flex rounded-md shadow-sm w-full">
          <button
            type="button"
            disabled={dateTypeDisabled}
            onClick={() => setDateType('single')}
            className={classNames(dateType === 'single' ? 'bg-green-200 text-green-900' : 'bg-white text-gray-900', "disabled:opacity-50 w-full relative inline-flex justify-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10")}
          >
            <CalendarIcon className="mr-1 h-5 w-5" />
            Single
          </button>
          <button
            type="button"
            disabled={dateTypeDisabled}
            onClick={() => setDateType('range')}
            className={classNames(dateType === 'range' ? 'bg-blue-200 text-blue-900' : 'bg-white text-gray-900', "disabled:opacity-50 w-full relative -ml-px inline-flex justify-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10")}
          >
            <CalendarDaysIcon className="mr-1 h-5 w-5" />
            Range
          </button>
        </span>
      </div>

      <div>
        <label htmlFor='schedule_type' className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Assign To</label>
        <span className="isolate inline-flex rounded-md shadow-sm w-full">
          <Dropdown
            emptyPlaceholder=""
            options={[
              {
                value: 'precinct',
                label: 'Precinct'
              }, {
                value: 'votingLocation',
                label: 'Voting Location'
              }, {
                value: 'default',
                label: 'Default'
              }
            ]}
            id="schedule_type"
            name="schedule_type"
            placeholder='What would you like to assign to'
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6"
            // @ts-ignore
            onChange={({target: {value}}: {target: {value: 'precinct' | 'votingLocation' | 'default'}}) => {
              setSelectedRoleId(undefined);
              setSelectedWorkplaceId(undefined);
              setAssignTo(value);
            }}
            selectedValue={assignTo}
          />
        </span>
      </div>

      <Flexor className="space-x-2">
        <div className="w-full">
          <label htmlFor='role' className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Role</label>
          <span className="isolate inline-flex rounded-md shadow-sm w-full">
            <Dropdown
              id={'role'}
              name={'role'}
              // @ts-ignore
              placeholder='Pick a role'
              emptyPlaceholder='No roles found'
              options={roleOptions}
              selectedValue={selectedRoleId}
              onChange={({target: {value}}) => setSelectedRoleId(value)}
            />
          </span>
        </div>
        {
          assignTo !== 'default' ? (
            <>
              {
                assignTo === 'precinct' ? (
                  <div className="w-full">
                    <label htmlFor='precinct' className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Precinct</label>
                    <span className="isolate inline-flex rounded-md shadow-sm w-full">
                      <Dropdown
                        id={'precinct'}
                        name={'precinct'}
                        placeholder='Pick a precinct'
                        emptyPlaceholder='No precinct found'
                        options={precincts.filter((p) => p.keyElectionId === selectedElectionId).map((p) => ({value: p.id, label: p.precinctName}))}
                        selectedValue={selectedWorkplaceId}
                        onChange={({target: {value}}) => setSelectedWorkplaceId(value)}
                      />
                    </span>
                  </div>
                ) : null
              }
              {
                assignTo === 'votingLocation' ? (
                  <div className="w-full">
                    <label htmlFor='voting_location' className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Voting Location</label>
                    <span className="isolate inline-flex rounded-md shadow-sm w-full">
                      <Dropdown
                        id={'voting_location'}
                        name={'voting_location'}
                        placeholder='Select a voting location'
                        emptyPlaceholder={'No precinct found'}
                        options={votingLocations.map((v) => ({value: v.id, label: v.locationName}))}
                        selectedValue={selectedWorkplaceId}
                        onChange={({target: {value}}) => setSelectedWorkplaceId(value)}
                      />
                    </span>
                  </div>
                ) : null
              }
            </>
          ) : null
        }
      </Flexor>

      <label htmlFor='role' className="w-full flex justify-between items-center text-sm font-medium leading-6 text-gray-900 mt-2">
        <span>Dates</span>
        {dateType === 'single' && dayMultiple ? <span>{dayMultiple.length} days</span> : null}
        {dateType === 'range' && dayRange ? <span>{getDateRangeDaysCount(dayRange)} days</span> : null}
      </label>
      <div className="z-50 mx-auto -mt-5 flex justify-center sm:scale-95 origin-center transform">
        {
          dateType === 'single' ? (
            <>
              {
                scheduleToEdit && dayMultiple && dayMultiple[0] ? (
                  <>
                    <DayPicker
                      showOutsideDays
                      data-testid='test_day_picker'
                      mode='single'
                      components={{DayContent: DayContentRenderer}}
                      selected={dayMultiple[0]}
                      defaultMonth={dayMultiple[0]}
                      // @ts-ignore
                      onSelect={(day) => setDayMultiple([day])}
                    />
                  </>
                ) : (
                  <DayPicker
                    showOutsideDays
                    data-testid='test_day_picker'
                    components={{
                      DayContent: DayContentRenderer
                    }}
                    mode={'multiple'}
                    onSelect={setDayMultiple}
                    selected={dayMultiple}
                  />
                )
              }
            </>
          ) : null
        }

        {
          dateType === 'range' ? (
            <DayPicker
              showOutsideDays
              mode={'range'}
              onSelect={setDayRange}
              selected={dayRange}
            />
          ) : null
        }
      </div>

      {
        startTime && endTime && (
          <div>
            <Flexor className="space-x-2 mt-2">
              <label htmlFor='start_time' className="block w-full text-sm font-medium leading-6 text-gray-900">Start Time</label>
              <label htmlFor='work_hours' className="block w-full text-sm font-medium leading-6 text-gray-900">End Time</label>
            </Flexor>
            <Flexor className="space-x-2">
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                type="time"
                defaultValue={startTime}
                onChange={({target: {value}}) => setStartTime(value)}
                name="start_time" />
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                type="time"
                defaultValue={endTime}
                onChange={({target: {value}}) => setEndTime(value)}
                name="end_time"
              />
            </Flexor>
          </div>
        )
      }
      <Flexor justify='end' className="my-4 pt-4 border-t">
        <Button size='sm' disabled={addDisabled} onClick={saveAndClose}>{scheduleToEdit ? 'Save' : 'Add'}</Button>
      </Flexor>
    </div>
  );
}
