import { PayloadAction } from '@reduxjs/toolkit';

import { InventoryEquipTypeSummary } from 'admin/src/types/Inventory';
import { SET_INVENTORY_EQUIPMENT_TYPE_SUMMARIES } from 'admin/src/actions/inventory';

export default inventoryEquipmentReducer;

export type InventoryEquipmentState = {
  equipTypeSummaries: {
    [customerId: string]: InventoryEquipTypeSummary[]
  }
}

const defaultState: InventoryEquipmentState = {
  equipTypeSummaries: {}
};

function inventoryEquipmentReducer(state: InventoryEquipmentState = defaultState, action: PayloadAction<any>): InventoryEquipmentState {
  switch(action.type) {
    case SET_INVENTORY_EQUIPMENT_TYPE_SUMMARIES:
      return {
        ...state,
        equipTypeSummaries: {
          ...state.equipTypeSummaries,
          [action.payload.customerId]: action.payload.summaries
        }
      }
    default:
      return state
  }
}

