import { useEffect } from 'react';

import { checkFeatureFlag } from 'admin/src/fetchers';
import { selectFeatureFlags } from 'admin/src/selectors';
import { AppState } from 'admin/src/store';

import { useAppDispatch, useAppSelector } from './index';

export const useIsFeatureFlagEnabled = (flag: string): boolean => {
  const dispatch = useAppDispatch();
  const flags = useAppSelector(selectFeatureFlags);
  const currentUser = useAppSelector((state: AppState) => state.user);

  useEffect(() => {
    if (flags[flag] === undefined) {
      checkFeatureFlag(dispatch, flag).then(() => {});
    }
  }, [currentUser]);

  return (flags[flag] && flags[flag]?.enabled) as boolean;
}
