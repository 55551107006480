import * as Sentry from '@sentry/react';

// If we want to split the workspaces into different "projects" on the sentry side, all we really need to do is pass in the DSN
function initialSetup (dsn?: string) {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: dsn || "https://8244b8c365482a0629bdb36b6ea8f341@o4507215555330048.ingest.us.sentry.io/4507215566340096",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: true,
          mask: ['.sentry-mask', '[data-sentry-mask]', '.mask']
        }),
      ],
      environment: process.env.NODE_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/ev-api(-dev|-test|-staging)?\.easyvoteapp\.com/,
        /^https:\/\/evmobileapp(dev|test|prod5)\.azurewebsites\.net/,
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    Sentry.setTag('build', process.env.REACT_APP_VERSION?.substring(0, 7))
  }
}

function identifyLoggedInUser(id: string, userId: string, emailAddress: string, customerId: string, customerName: string, customerState: string) {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setUser({
      id,
      username: userId,
      email: emailAddress
    });

    if (customerId)
      Sentry.setTag('customer_id', customerId);

    if (customerName)
      Sentry.setTag('customer_name', customerName);

    if (customerState)
      Sentry.setTag('customer_state', customerState);
  }
}

export {
  initialSetup,
  identifyLoggedInUser
}
