import { createSelector } from 'reselect';
import { AppState } from "../store";
import { CustomerInfo } from "../types/CustomerInfo";
import { OrderConfig } from "../types/User";

export const selectOrderConfigs = (state: AppState): OrderConfig[] => state.orders.orderConfigs;
export const selectCustomersFromOrderConfigs = createSelector(
  selectOrderConfigs,
  (orderConfigs: OrderConfig[]) => orderConfigs
    .filter(oc => !!oc.customer)
    .map(oc => oc.customer as CustomerInfo)
    .sort((customerA: CustomerInfo, customerB: CustomerInfo) => {
      return customerA.customerName.localeCompare(customerB.customerName);
    })
);
export const getOrders = () => {
  return (state: AppState) => {
    return state.orders;
  };
}
