import React, { useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {RowGroupingModule} from '@ag-grid-enterprise/row-grouping';
import {SideBarDef} from 'ag-grid-community';

import { DeploymentDashboardEquipmentSummary } from 'admin/src/types/Inventory';
import LoadingOverlay from 'admin/src/components/Pollworker/utils/LoadingOverlay';
import { cn } from 'shared/src/utils';

export interface DeploymentEquipmentSummaryTableProps {
  className?: string | undefined,
  loading: boolean,
  summaries: DeploymentDashboardEquipmentSummary[]
}

const ColDef = [
  {
    field: 'locationName',
    enableRowGroup: true,
    sortable: true,
    filter: true,
  },
  {
    field: 'equipmentTypeName',
    enableRowGroup: true,
    sortable: true,
    filter: true,
  },
  {
    field: 'available'
  },
  {
    field: 'assembled'
  },
  {
    field: 'deploymentOnTruck'
  },
  {
    field: 'delivered'
  },
  {
    field: 'pickupOnTruck'
  },
  {
    field: 'returnedToWarehouse'
  },
  {
    field: 'cocSignedOut'
  },
  {
    field: 'cocSignedIn'
  },
];

const sidebarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanelParams: {
        suppressPivotMode: true,
      }
    },
    'filters',
  ],
  defaultToolPanel: '',
  position: 'right',
};

const defaultColDef = {
  sortable: false,
  resizable: true,
  filter: false,
  enableCellChangeFlash: false,
};

export default function DeploymentEquipmentSummaryTable(props: DeploymentEquipmentSummaryTableProps) {
  const gridRef = useRef<AgGridReact>(null);
  const { className, loading, summaries } = props;

  useEffect(() => {
    if (gridRef.current) {
      if (loading) {
        gridRef.current.api?.showLoadingOverlay();
      }
      else {
        gridRef.current!.api?.hideOverlay();
      }
    }
  }, [gridRef, loading]);

  return (
    <div className={cn(className, 'ag-theme-balham h-full w-full')}>
      <AgGridReact
        ref={gridRef}
        gridId="deployment-equipment-summary-table"
        getRowId={params => params.data.locationId}
        loadingOverlayComponent={() => <LoadingOverlay isPreview={false} animate infoText={'Loading Deployment Data...'} />}
        enableRangeSelection
        allowContextMenuWithControlKey
        animateRows={false}
        rowData={summaries}
        modules={[RowGroupingModule]}
        noRowsOverlayComponent={() => (<div>No data found</div>)}
        columnDefs={ColDef}
        enableBrowserTooltips
        sideBar={sidebarConfig}
        defaultColDef={defaultColDef}
      />
    </div>
  )
}
