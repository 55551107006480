import React, { useCallback, useMemo, useState } from 'react';
import { PollworkerTimeclockEntry, WorkSchedule } from 'admin/src/types';
import { classNames, cn } from 'shared/src/utils';
import dayjs from 'dayjs';
import { Button, Dialog, Input } from 'shared/src/components/ui';
import { Flexor, Spinner } from 'shared/src/components';
import Timeclock from './TimeClock'
import { BuildingOffice2Icon, ChevronDownIcon, ChevronRightIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Heading } from 'shared/src/components/SectionHeading';
import { editPollworkerTimeclockEntryTimes } from 'admin/src/fetchers';
import { updateTrackerWorkSchedule } from 'admin/src/actions';
import { useAppDispatch } from 'admin/src/hooks';

export default PollworkerScheduleListItem;

export interface PollworkerScheduleListItemProps {
  className?: string | undefined;
  schedule: WorkSchedule;
  onPollworkerClick: (schedule: WorkSchedule) => void;
  onClockIn: (schedule: WorkSchedule) => Promise<void>;
  onClockOut: (schedule: WorkSchedule) => Promise<void>;
}

function PollworkerScheduleListItem({ className, schedule, onPollworkerClick, onClockIn, onClockOut }: PollworkerScheduleListItemProps) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editEntryDialogOpen, setEditEntryDialogOpen] = useState<boolean>(false);
  const [entryToEdit, setEntryToEdit] = useState<PollworkerTimeclockEntry | null>(null);
  const [editingClockInTimeString, setEditingClockInTimeString] = useState<string>('');
  const [editingClockOutTimeString, setEditingClockOutTimeString] = useState<string>('');

  const mostRecentTimeclockEntry = useMemo(
    () => schedule?.timeclockEntries?.filter(x => x.timeIn)
            .sort((a: PollworkerTimeclockEntry, b: PollworkerTimeclockEntry) => (a.timeIn as string) > (b.timeIn as string) ? 1 : -1)
            .pop(),
      [schedule]
  );

  const wrongDate = useMemo(
    () => {
      const scheduledDate = schedule.workDate.split('T')[0];

      return scheduledDate !== dayjs().format('YYYY-MM-DD');
    },
    [schedule]
  );

  const handleEditClick = (entry: PollworkerTimeclockEntry) => {
    setEntryToEdit(entry);
    setEditEntryDialogOpen(true);

    const clockIn = new Date(entry.timeIn as string)
    setEditingClockInTimeString(dayjs(clockIn).format('HH:mm'));

    if (entry.timeOut) {
      const clockOut = new Date(entry.timeOut);
      setEditingClockOutTimeString(dayjs(clockOut).format('HH:mm'));
    } else {
      setEditingClockOutTimeString('--:--');
    }
  };

  const handleEditClockInChange = (newTime: string) => {
    setEditingClockInTimeString(newTime);
  };

  const handleEditClockOutChange = (newTime: string) => {
    setEditingClockOutTimeString(newTime);
  };

  const handleSaveTimeChanges = useCallback(() => {
    if (entryToEdit) {
      const workDateStr = schedule.workDate.split('T')[0];
      const newClockIn = dayjs(`${workDateStr}T${editingClockInTimeString}`);
      let newClockOut = null;
      let newTimeIn = entryToEdit.timeIn;
      let newTimeOut = entryToEdit.timeOut;

      if (newClockIn.isValid()) {
        newTimeIn = newClockIn.utc().toISOString();
      }

      if (editingClockOutTimeString) {
        newClockOut = dayjs(`${workDateStr}T${editingClockOutTimeString}`);

        if (newClockOut.isValid()) {
          newTimeOut = newClockOut.utc().toISOString();
        }
      }

      if (newTimeIn) {
        editPollworkerTimeclockEntryTimes(entryToEdit.id, newTimeIn, newTimeOut).then((updatedEntry: PollworkerTimeclockEntry) => {
          const newSchedule: WorkSchedule = {
            ...schedule
          }
          const indexToUpdate = newSchedule.timeclockEntries.findIndex(entry => entry.id === updatedEntry.id);

          if (indexToUpdate !== -1) {
            newSchedule.timeclockEntries = [
              ...newSchedule.timeclockEntries.slice(0, indexToUpdate),
              updatedEntry,
              ...newSchedule.timeclockEntries.slice(indexToUpdate + 1)
            ];
          }

          dispatch(updateTrackerWorkSchedule(newSchedule));
          setEntryToEdit(null);
          setEditEntryDialogOpen(false);
        });
      }
    }
  }, [editingClockInTimeString, editingClockOutTimeString, schedule, dispatch]);

  const handleClockIn = useCallback(() => {
    setLoading(true);

    onClockIn(schedule).finally(() => {
      setLoading(false);
    });
  }, [onClockIn]);

  const handleClockOut = useCallback(() => {
    setLoading(true);

    onClockOut(schedule).finally(() => {
      setLoading(false);
    });
  }, [onClockOut]);

  const [clockTime, clockedOut, actionLabel, backgroundColor] = useMemo(() => {
    let backgroundColor = 'bg-gray-500';
    const clockedOut = !mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.timeOut;
    let clockTime: number;

    if (!mostRecentTimeclockEntry) {
      const startTime = dayjs(schedule.startTime, "h:mm A")
      clockTime = dayjs(schedule.workDate)
        .add(startTime.hour(), 'hours')
        .add(startTime.minute())
        .toDate().getTime();
    } else {
      clockTime = dayjs(mostRecentTimeclockEntry.timeIn).toDate().getTime();
    }

    if (mostRecentTimeclockEntry && !mostRecentTimeclockEntry.timeOut) {
      backgroundColor = 'bg-green-500';
    } else if (mostRecentTimeclockEntry) {
      backgroundColor = 'bg-yellow-600';
    } else if (dayjs().toDate().getTime() > clockTime) {
      backgroundColor = 'bg-red-500';
    }

    return [
      clockTime,
      clockedOut,
      wrongDate
        ? 'Clock In/Out Unavailable'
        : (!mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.timeOut) ? 'Clock In' : 'Clock Out',
      backgroundColor
    ]
  }, [mostRecentTimeclockEntry]);

  return (
    <div className={cn(className, 'flex flex-wrap items-center justify-between p-3 px-4 shadow-sm')}>
      {schedule.timeclockEntries.length > 0 && (
        <div className="cursor-pointer mr-3" onClick={() => setExpanded(currentExpanded => !currentExpanded)}>
          {expanded ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4" />}
        </div>
      )}
      <div className="cursor-pointer" onClick={() => onPollworkerClick(schedule)}>
        <div className='text-lg font-medium'>{schedule.pollworkerFullName}</div>
        <div className='text-sm'>{schedule.roleName}, <i>Schedule: {schedule.startTime}-{schedule.endTime}</i></div>
      </div>
      <div className="flex-grow flex justify-center space-x-4">
        <div className={classNames(backgroundColor, 'text-white px-4 py-1 rounded-md')}>
          <Timeclock scheduledTimeUTC={clockTime} isClockedIn={!clockedOut} />
        </div>
      </div>
      <div>
        <Button disabled={loading || wrongDate} className="w-full" variant="secondary"
          onClick={() => clockedOut ? handleClockIn() : handleClockOut()}>
          <Spinner show={loading} />
            {!loading && actionLabel}
        </Button>
      </div>
      <div className="overflow-auto mt-3 max-h-40 w-full">
        {expanded && (
          <div className="flex justify-around font-semibold ">
            <div className="text-center w-24">Clocked In</div>
            <div className="text-center w-24">Clocked Out</div>
          </div>
        )}
        {expanded && schedule.timeclockEntries.map(entry => (
          <TimeclockEntry entry={entry} onEditClick={() => handleEditClick(entry)} />
        ))}
      </div>
      <Dialog.Dialog variant="default" size="sm" open={editEntryDialogOpen} onOpenChange={setEditEntryDialogOpen}>
        <Dialog.DialogContent>
          <Dialog.DialogHeader>
            <Dialog.DialogTitle>
              <BuildingOffice2Icon />
              <Heading>Edit Time Entry</Heading>
            </Dialog.DialogTitle>
            <Dialog.DialogDescription>
              Use the controls below to change {schedule.pollworkerFullName}'s time clock entry.
            </Dialog.DialogDescription>
          </Dialog.DialogHeader>
          <Flexor className="mt-10">
            <div className="h-[150px]">
              Clocked In
              <div className="w-[160px]">
                <Input type="time" value={editingClockInTimeString} onChange={event => {
                  handleEditClockInChange(event.target.value);
                }} />
              </div>
            </div>
            <div className="h-[150px]">
              Clocked Out
              <div className="w-[160px]">
                <Input type="time" value={editingClockOutTimeString} onChange={event => {
                  handleEditClockOutChange(event.target.value);
                }} />
              </div>
            </div>
          </Flexor>
          <Dialog.DialogFooter>
            <Button variant='primary' type="submit"
                    onClick={handleSaveTimeChanges}>Save</Button>
            <Button variant="secondary" onClick={() => setEditEntryDialogOpen(false)}>Cancel</Button>
          </Dialog.DialogFooter>
        </Dialog.DialogContent>
      </Dialog.Dialog>
    </div>
  )
}

function TimeclockEntry({ entry, onEditClick }: { entry: PollworkerTimeclockEntry, onEditClick: (entryToEdit: PollworkerTimeclockEntry, timeIn: boolean) => void }) {
  const formatTime = (time: string) => dayjs.utc(time).local().format('h:mm A');

  return (
    <div className="flex justify-around">
      <div className="flex gap-1 justify-around items-center w-24">
        {formatTime(entry.timeIn as string)}
        <PencilSquareIcon className="cursor-pointer h-5 w-5" onClick={() => onEditClick(entry, true)} />
      </div>
      <div className="flex gap-1 justify-around items-center w-24">
        {entry.timeOut ? formatTime(entry.timeOut) : '-'}
        {entry.timeOut && <PencilSquareIcon className="cursor-pointer h-5 w-5" onClick={() => onEditClick(entry, false)} />}
      </div>
    </div>
  )
}
