import { AnyAction } from "@reduxjs/toolkit";
import { CURRENT_CUSTOMER_ACTION_TYPES } from "../actions/currentCustomer";

const defaultPollworkerWebsiteState = {};

export function currentCustomerReducer(state = defaultPollworkerWebsiteState, action: AnyAction) {
  switch (action.type) {
    case CURRENT_CUSTOMER_ACTION_TYPES.SET_CURRENT_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
