import * as React from 'react';
import { cva, type VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { cn } from "../../utils";

const gridVariants = cva(
  'grid gap-6', {
    variants: {
      cols: {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        4: 'grid-cols-4',
        6: 'grid-cols-6',
        8: 'grid-cols-8',
        10: 'grid-cols-10',
        12: 'grid-cols-12',
      },
      subgrid: {
        true: 'grid-cols-subgrid',
        false: ''
      }
    },
    defaultVariants: {
      cols: 12,
      subgrid: false,
    }
  }
);

export interface GridProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof gridVariants> {}

const Grid = React.forwardRef<
  HTMLDivElement,
  GridProps
>(({className, cols, ...props}, ref) => {
  return (
    <div className={twMerge(cn(gridVariants({cols, className})))} {...props} />
  )
});

const gridColVariants = cva(
  'grid gap-6', {
    variants: {
      span: {
        1: 'col-span-1',
        2: 'col-span-2',
        3: 'col-span-3',
        4: 'col-span-4',
        5: 'col-span-5',
        6: 'col-span-6',
        7: 'col-span-7',
        8: 'col-span-8',
        9: 'col-span-9',
        10: 'col-span-10',
        11: 'col-span-11',
        12: 'col-span-12',
      },
    },
    defaultVariants: {
      span: 1,
    }
  }
);

export interface GridColProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof gridColVariants> {}

const GridCol = ({span, className, ...props}: GridColProps) => {
  return <div className={twMerge(cn(gridColVariants({ span, className })))} {...props} />
}

export {
  Grid,
  GridCol,
}
