import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkSchedule } from 'pollworker/src/types';
import {AppState} from './store';

export interface TimeclockState {
  coworkerInfoToDisplay: WorkSchedule | null;
  coworkerSchedules: WorkSchedule[];
}

const initialState: TimeclockState = {
  coworkerInfoToDisplay: null,
  coworkerSchedules: []
};

const slice = createSlice({
  name: 'timeclock',
  initialState,
  reducers: {
    setCoworkerInfoToDisplay: (state, action: PayloadAction<WorkSchedule | null>) => {
      state.coworkerInfoToDisplay = action.payload;
    },
    setCoworkerSchedules: (state, action: PayloadAction<WorkSchedule[]>) => {
      state.coworkerSchedules = action.payload;
    },
    updateCoworkerSchedule: (state, action: PayloadAction<WorkSchedule>) => {
      const index = state.coworkerSchedules.findIndex(schedule => schedule.Id === action.payload.Id);

      if (index === -1) {
        return
      } else {
        state.coworkerSchedules[index] = action.payload;
      }
    }
  }
});

export const {
  setCoworkerInfoToDisplay,
  setCoworkerSchedules,
  updateCoworkerSchedule
} = slice.actions;

export const selectCoworkerInfoToDisplay = (state: AppState) => state.timeclock.coworkerInfoToDisplay;
export const selectCoworkerSchedules = (state: AppState) => state.timeclock.coworkerSchedules;

export default slice.reducer;
